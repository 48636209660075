import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // open modal on page load
  $('.modal.open').each(function () { $(this).modal('show') })

  $('.terms-link').on('click', function(){
    $('#terms_of_use_modal').modal('toggle')
  })

  $('.subscriber-link').on('click', function(e){
    const is_subscriber = $(this).data('subscriber')

    if(!is_subscriber) {
      e.preventDefault()
      $('#subscription_modal').modal('toggle')
    }
    else {
      if($(this).data('modal')){
        $(`#${$(this).data('modal')}`).modal('toggle')
      }
    }
  })

  $('.subscriber-link-credit-analysis').on('click', function(e) {
    e.preventDefault()
    const is_subscriber = $(this).data('subscriber')
    const reached_limit_of_credit_analysis = !$(this).data('number-of-credit-analysis-remaining')
    const href = $(this).data('href')

    if (!is_subscriber) {
      $('#credit_analysis_subscription_modal').modal('toggle')

      if(href){
        $('.link_to_request').attr('href', href)
      }
    }
    else {
      if (!reached_limit_of_credit_analysis){
        const modal_id = $(this).data('modal') || 'analysis_modal'
        $( `#${modal_id}`).modal('toggle')
      }
      else{
        $('#reached_limit_of_credit_analysis_modal').modal('toggle')
      }
    }
  })

  $('.delete-property-link').on('click', function(e){
    e.preventDefault()
    $('#delete-property-modal').modal('toggle')
    $('#property-resume').html($(this).data('property-resume'))
    $('#delete-property-link-modal').attr('href', $(this).data('href'))
  })

  // PREMIUM FEATURE
  $('.premium-feature-link').on('click', function(e){
    let subscriber = $(this).data('subscriber')
    let skip_subscriber_check = !!($(this).data('skip-subscriber-check'))
    let authorized = $(this).data('authorized')
    let skip_authorized_check = !!($(this).data('skip-authorized-check'))
    let properties = $(this).data('number-of-properties')
    let callback = $(this).data('callback')

    if ( !subscriber && !skip_subscriber_check ) {
      e.preventDefault()
      fireSubscriptionModal()
    }
    else if ( !authorized && !skip_authorized_check ) {
      e.preventDefault()
      fireExceededPremiumModal(properties)
    }
    else if ( callback ) {
      eval(callback)(e)
    }
  })
}

component.expose = {
  fireSubscriptionModal() {
    $('#subscription_modal').modal('toggle')
  },

  fireExceededPremiumModal(properties) {
    $('#exceeded-premium-feedback-modal').find('#number_of_properties').text(properties)
    $('#exceeded-premium-feedback-modal').modal('toggle')
  },

  checkPremiumFeature(e) {
    return $(e).data('subscriber')
  }
}

export default component
