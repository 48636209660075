import { Component } from "@page/component"

const component = new Component({
  mixins: ['app/billing/plans/_checkout.js']
})

component.ready = function () {
  equalizeHeight('.properties')
  equalizeHeight('.subscription>.details')

  if (window.matchMedia("(min-width: 767.98px)").matches)
    equalizeHeight('.plan-height')

  initOnDemandPlans()

  $('.choose-plan').on('click', function(e) {
    Loading.show()
    let subscription_id = _storage['subscription']['id']
    let plan = ($(this).data('plan') === 'Controle') ? 'control' : 'landlord'
    let chosen = _storage[plan]

    fetchPlan(chosen).done(function(response) {
      let plan_id = response.id

      if(!!subscription_id) {
        // switch plan
        let action       = $(e.target).data('action')
        let partial_name = (action == 'change_to_basic') ? action : 'change_to_premium'

        $.ajax({
          method: 'GET',
          url: `/app/billing/subscriptions/${subscription_id}/fill_modal`,
          data: { partial_name, plan_id }
        }).done(function () {
          Loading.hide()
        })
      }
      else {
        // subscribe plan
        startCheckout(plan_id)
      }
    })
  })
}

component.expose = {
  initOnDemandPlans() {
    refreshStorage()

    $('.properties button.minus').on('click', function() {
      let input = $(this).siblings('input')
      let val = parseInt(input.val()) - 1
      input.val( (val <= 0) ? 1 : val ).trigger('change')
    })

    $('.properties button.more').on('click', function() {
      let input = $(this).siblings('input')
      let val = parseInt(input.val()) + 1
      input.val( (val <= 0) ? 1 : val ).trigger('change')
    })

    $('.properties input').on('change', function() {
      refreshStorage()

      let plan = ($(this).data('plan') === 'Controle') ? 'control' : 'landlord'
      let input = $(this)

      setTimeout(function(){
        const disableButton = function(button){
          $(button).removeClass($(button).data('enabled-class'))
          $(button).addClass('btn-secondary disabled')
        }
        const enableButton = function(button){
          $(button).removeClass('btn-secondary disabled')
          $(button).addClass($(button).data('enabled-class'))
        }

        // update price
        $(`#price-${plan}`).text(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(_storage[plan]['price']))

        // enable|disable minus button
        if (_storage[plan]['number_of_properties'] <= 1) {
          disableButton($(input).siblings('.minus'))
        } else{
          enableButton($(input).siblings('.minus'))
        }

        // enable|disable choose-plan button
        let btn_choose_plan = $(`#btn-${plan}`)
        let btn_choose_plan_footer = $(`#btn-${plan}-footer`)

        if (`${_storage['subscription']['name']}` === `${_storage[plan]['name']}`) {
          disableButton(btn_choose_plan)
          disableButton(btn_choose_plan_footer)

          if (_storage['subscription']['locked'] === true){
            $(btn_choose_plan).text('Aguardando pagamento')
            $(btn_choose_plan_footer).find('span').text('Aguardando pagamento')
          } else{
            $(btn_choose_plan).text('Plano atual')
            $(btn_choose_plan_footer).find('span').text('Plano atual')
          }

        } else {
          enableButton(btn_choose_plan)
          $(btn_choose_plan).text($(btn_choose_plan).data('text'))

          enableButton(btn_choose_plan_footer)
          $(btn_choose_plan_footer).find('span').text($(btn_choose_plan).data('text'))
        }
      }, 0)
    })

    $("#btn-basic-footer").on('click', function() {
      $("#btn-basic")[0].click()
    })

    $("#btn-landlord-footer").on('click', function() {
      $("#btn-landlord").click()
    })

    $("#btn-control-footer").on('click', function() {
      $("#btn-control").click()
    })
  },

  refreshStorage() {
    window._storage = {
      subscription: {
        id: $('#current_subscription').data('id'),
        beginning_of_the_name: $('#current_subscription').data('plan'),
        number_of_properties: parseInt($('#current_subscription').data('number-of-properties')) || 0,
        name: `${$('#current_subscription').data('plan')} ${$('#current_subscription').data('number-of-properties')}`,
        locked: $('#current_subscription').data('locked'),
      },
      landlord: {
        beginning_of_the_name: $('#btn-landlord').data('plan'),
        number_of_properties: parseInt($('#input-landlord').val()),
        name: `${$('#btn-landlord').data('plan')} ${$('#input-landlord').val()}`,
        price: sumPrice('landlord', parseInt($('#input-landlord').val())),
        trial_days: $('#btn-landlord').data('trial-days')
      },
      control: {
        beginning_of_the_name: $('#btn-control').data('plan'),
        number_of_properties: parseInt($('#input-control').val()),
        name: `${$('#btn-control').data('plan')} ${$('#input-control').val()}`,
        price: sumPrice('control', parseInt($('#input-control').val())),
        trial_days: $('#btn-control').data('trial-days')
      }
    }
  },

  sumPrice(plan, number_of_properties) {
    const landlord_price = 34.90
    const landlord_additional_price = 14.90
    const control_price = 64.90
    const control_additional_price = 24.90
    let additional_properties = 0
    if (number_of_properties > 1)
      additional_properties = number_of_properties - 1
    return eval(`${plan}_price`) + (additional_properties * eval(`${plan}_additional_price`))
  },

  fetchPlan(chosen) {
    return $.ajax({
      method: 'POST',
      url: `/app/billing/plans/fetch`,
      data: { fetch: chosen }
    })
  },

  redirectToContinue() {
    const params = new URLSearchParams(window.location.search)
    let pathOnComplete = params.get('redirectPath')

    if(pathOnComplete !== null){
      window.location.href = pathOnComplete
    }
  }
}

export default component
