import { Component } from "@page/component"

const component = new Component()
const urlParams = new URLSearchParams(window.location.search)

component.expose = {
    checkSalesFlow() {
        if(urlParams.get('sales')){
            return true
        } else {
            return false
        }
    
    }
}

export default component
