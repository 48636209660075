import { Autoloader } from '@lib/page/autoloader'

const files = require.context('.', true, /\.js$/)

const autoloader = new Autoloader(files, { prefix_with: 'app'} )

autoloader.start()

// expose autoloader (for debug)
window.autoloaders = window.autoloaders || []
window.autoloaders.push(autoloader)
