import { Component } from "@page/component"

const component = new Component({
  mixins: [ 'app/billing/commercial_orders/_modals.js' ]
})

component.ready = function() {
  $('.premium-switch-lease-status').on('click', function(e){
    e.preventDefault();
    let _switch = $(e.target)
    let subscriber = _switch.data('subscriber')
    let authorized = _switch.data('authorized')
    let properties = _switch.data('number-of-properties')

    const performChange = function() {
      Loading.show()
      changeLeaseStatus(_switch.data('id')).done(function() {
        window.location.href = `/app/landlords/properties/${_switch.data('property-id')}/leases`
      })
    }

    if(_switch[0].checked === true) {
      if ( ! subscriber )
        fireSubscriptionModal()
      else if ( ! authorized )
        fireExceededPremiumModal(properties)
      else
        performChange() // activate
    }
    else {
      performChange() // deactivate
    }
  })
}

component.expose = {
  changeLeaseStatus(lease_id) {
    return $.ajax({ method: 'GET', url: `/app/landlords/rents/leases/${lease_id}/change_lease_status` })
  },
}

export default component
