export class Autoloader {
  constructor(files, options= {}) {
    this.components = []
    files.keys().forEach((key) => {
      try {
        let module = files(key)
        let component = module.default

        if (component) {
          if(options.prefix_with)
            component.key = `${options.prefix_with}/${key.match(/\.\/(.*\.js$)/)[1]}`
          else
            component.key = key
          this.components.push(component)
        }
      } catch (e) {
        console.error(`[COMPONENT_MODULE_LOAD_ERROR] key: ${key}`)
        console.error(e)
      }
    })
  }

  start() {
    // initialize common components
    for (let component of this.components.filter(component => component && component.key.match(/^app\/common\/.*$/) )) {
      this._initializeComponent(component.key)
    }
    // initialize page component
    this._initializeComponent(`${RailsHelper.currentModule()}.js`)
  }

  findComponent(key) {
    return this.components.filter(component => component && component.key === key )[0]
  }

  componentsInitialized() {
    return this.components.filter(component => component && component.loaded === true )
  }

  // private
  _initializeComponent(key) {
    let component = this.findComponent(key)

    if(!component) {
      console.warn(`[COMPONENT_NOT_FOUND] key: ${key}`)
      return
    }

    // prevents circular dependency in component.mixins
    if(component.loaded) {
      return
    }

    // expose attributes of component.expose
    if (component.expose) {
      for (let entry of Object.entries(component.expose)) {
        global[entry[0]] = entry[1]
      }
    }

    // call component.start function
    if (component.start) {
      try {
        component.start()
      } catch (e) {
        console.error(`[COMPONENT_START_ERROR] key: ${key}`)
        console.error(e)
      }
    }

    // call component.ready function
    if (component.ready) {
      document.addEventListener("DOMContentLoaded", function () {
        component.ready()
      })
    }

    // set component as loaded
    component.loaded = true

    // initialize component.mixins
    if (component.mixins) {
      for (let mixin of component.mixins) {
        this._initializeComponent(mixin)
      }
    }
  }
}
