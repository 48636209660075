import iziToast from "iziToast"

export class FormHandler{
  constructor(){
    let self = this
    this.$search_switch =  $("input[name='search_switch']")
    this.$input_search = $("#autocomplete")
    this.$input_street = $(".street")
    this.$input_neighbourhood = $(".neighbourhood")
    this.$input_city = $(".city")
    this.$input_state = $(".state")
    this.$input_number = $(".number")
    this.$input_complement = $(".complement")
    this.$input_postal_code = $(".cep")

    if(!this.$input_search.length > 0)
      return

    // enable external services to find address
    this.enableSearchByAddress()
    this.enableSearchByPostalCode()

    // toggle search on switch radio
    this.enableSearchSwitch()

    // if have validation errors, then we need toggle search (to cep)
    if (this.hasInvalidFields()) $('label[for="radio_cep"]').click()

    // if already exists an address, then we need fill search input
    this.fillSearchWithCurrentAddress()

    // fill search input when change address fields
    this.$input_street.on('change', ()=> self.fillSearchWithCurrentAddress())
    this.$input_neighbourhood.on('change', ()=> self.fillSearchWithCurrentAddress())
    this.$input_city.on('change', ()=> self.fillSearchWithCurrentAddress())
    this.$input_state.on('change', ()=> self.fillSearchWithCurrentAddress())

    // clean address fields when search is empty
    this.$input_search.on('change', ()=> {
      if (self.$input_search.val() === ''){
        self.$input_street.val('')
        self.$input_neighbourhood.val('')
        self.$input_city.val('')
        self.$input_state.val('')
      }
    })
  }

  enableSearchByAddress(){
    let self = this

    let autocomplete = new google.maps.places.Autocomplete(
      (self.$input_search[0]),
      { types: ['address'], componentRestrictions: {'country': 'br'} }
    )

    autocomplete.setFields(["address_component"]);

    autocomplete.addListener("place_changed", ()=>{
      const place = autocomplete.getPlace();
      self.fillFields(self._parsePlaceAddress(place))
    })
  }

  enableSearchByPostalCode(){
    let self = this

    self.$input_postal_code.mask("99999-999", {
      onComplete: function () {
        const cep = self.$input_postal_code.val().replace(/[^0-9]/, "")

        // Validação do CEP caso o CEP não possua 8 números, então cancela a consulta
        if (cep.length != 8) return false

        const displayError = function(error){
          iziToast.error({ title: 'Erro', message: error, position: 'topRight'})
        }

        $.ajax({ method: 'GET', url: `/v1/addresses/search_cep/${cep}` })
          .done(function(response) {
            if (response["error"])
              displayError(response["error"])
            else {
              try {
                self.fillFields(response)
              } catch (ex) {
                displayError('Não foi possível preencher os campos do formulário')
              }
            }
          })
          .fail(function(xhr, status, message) {
            displayError('Não foi possível realizar a consulta')
          })
      }
    })
  }

  enableSearchSwitch(){
    let self = this
    this.$search_switch.each((i, element)=> $(element).attr('disabled', false))
    this.$search_switch.on('change', ()=> self.toggleSearch())
  }

  fillFields(address){
    this.$input_street.val(address.street)
    this.$input_neighbourhood.val(address.neighborhood)
    this.$input_city.val(address.city)
    this.$input_state.val(address.state)
    this.$input_number.focus()
    if (address.postal_code)
      this.$input_postal_code.val(address.postal_code)
  }

  fillSearchWithCurrentAddress(){
    let address = this._currentAddress(), text = ''
    if(address.street !== ''){
      text += `${address.street} - `
      if(address.neighbourhood) text += `${address.neighbourhood}, `
      if(address.city)          text += `${address.city} - `
      if(address.state)         text += `${address.state}`
      if(address.country)       text += `, ${address.country}`
    }
    this.$input_search.val(text)
  }

  hasInvalidFields(){
    return ( $('.field_with_errors').length > 0 )
  }

  toggleSearch(){
    let $search_fields = $(".search_field")
    let $postal_code_fields = $(".postal_code_fields")
    let $shared_fields = $(".shared_fields")

    $search_fields.toggleClass('d-none')
    let active_search = $search_fields.not('.d-none').data('type')

    if ( active_search === 'search_by_address'){
      this._hide($postal_code_fields)
      this._show($shared_fields)
    }
    else {
      this._show($shared_fields)
      this._show($postal_code_fields)
    }
  }

  _hide(elements){
    $(elements).each((i, element)=> $(element).addClass('d-none'))
  }

  _show(elements){
    $(elements).each((i, element)=> $(element).removeClass('d-none'))
  }

  _currentAddress(){
    return {
      street: this.$input_street.val(),
      neighbourhood: this.$input_neighbourhood.val(),
      city: this.$input_city.val(),
      state: this.$input_state.val(),
      country: 'Brasil',
      postal_code: this.$input_postal_code.val(),
      number: this.$input_number.val(),
      complement: this.$input_complement.val()
    }
  }

  _parsePlaceAddress(place){
    let address = {}
    const place_references = [
      {key: 'street',       value: 'long_name',   type: 'route' },
      {key: 'neighborhood', value: 'long_name',   type: 'sublocality_level_1' },
      {key: 'city',         value: 'long_name',   type: 'administrative_area_level_2' },
      {key: 'state',        value: 'short_name',  type: 'administrative_area_level_1' },
      {key: 'country',      value: 'long_name',   type: 'country' },
      {key: 'postal_code',  value: 'long_name',   type: 'postal_code' },
    ]

    for (const component of place.address_components) {
      const ref = place_references.filter(ref => ref.type === component.types[0])[0]
      if (ref)
        address[ref.key] = component[ref.value]
    }
    return address
  }
}
