import { Component } from "@page/component"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

const component = new Component()

component.ready = function() {
  // step 1
  toggleEndDate()
  flatpickrEndDate()
}

component.expose = {
  flatpickrEndDate() {
    let elements = $('.flatpickr-end-date')
    if(elements.length) {
      $(elements).each(function(){
        let input = $(this)
        let value = $(input).val()
        let date = (value != '' && moment(value)._isValid) ? moment(value).toDate() : new Date()

        if(!$(input).hasClass('input')){
          flatpickr($(input), {
            locale: Portuguese,
            defaultDate: date,
            enableTime: false,
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d/m/Y",
          })
        }
      })
    }
  },

  toggleEndDate() {
    let checkEndDate = $('#endDateCheck')
    if (checkEndDate.is(':checked')) {
      $('.end-date-form').hide()
      $('#rent_lease_end_date').val('')
    } else {
      $('.end-date-form').show()
      flatpickrEndDate()
    }

    $(checkEndDate).on('change', function () {
      toggleEndDate()
    })
  },

}

export default component
