import { Component } from "@page/component"

import Chocolat from 'chocolat' // chocolat 1.0.0

const component = new Component()

component.start = function () {
  $.fn.Chocolat = Chocolat
}

component.ready = function () {
  initGallery()

  $('.nav-scroll > a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    if ($(e.target).data('tab-url') === 'listing')
      fireTurboModal()
  })

  $('.switch_listing_status').on('click', function (e) {
    e.preventDefault();
    let _switch = $(e.target)

    if (_switch[0].checked === true) {
      Loading.show()
      changeListingStatus(_switch.data('id')).done(function () {
        window.location.href = `/app/landlords/properties/${_switch.data('property-id')}/listing`
      })
    } else {
      fireDisableListingModal()
    }
  })

  $('.button_listing_status').on('click', function (e) {
    e.preventDefault();
    let _btn = $(e.target)

    Loading.show()
    changeListingStatus(_btn.data('id')).done(() => {
      window.location.href = `/app/landlords/properties`
    })
  })



  // share listing
  $(document).on('click', '.share-listing', function (e) {
    let button = $(e.target)
    let share_modal = $('#listing-share-modal')

    if (button.data('listing-active')) {
      if (button.data('content-to') == 'invite') {
        share_modal.find('.modal-title').html('Convidar Interessados')
        share_modal.find('.content').hide()
        share_modal.find('.content.invite').show()
      } else {
        share_modal.find('.modal-title').html('Compartilhe seu anúncio')
        share_modal.find('.content').hide()
        share_modal.find('.content.default').show()
      }
      share_modal.modal('toggle')
    } else {
      $('#listing-share-unavailable-modal').modal('toggle')
    }
  })

  // disable listing reason
  $('.check-disable-reason').on('change', function (event) {
    configureCheckboxesForDisableListing(event)
  })
}

component.expose = {
  initGallery() {
    Chocolat(document.querySelectorAll('.gallery-desktop-image'), {})
    Chocolat(document.querySelectorAll('.gallery-mobile-image'), {})
  },

  changeListingStatus(listing_id) {
    return $.ajax({ method: 'GET', url: `/app/landlords/properties/listings/${listing_id}/change_listing_status` })
  },

  fireDisableListingModal() {
    $('#disable-listing-modal').modal('toggle')
  },

  configureCheckboxesForDisableListing(event) {
    let target = event.target
    let checkboxes = $('.check-disable-reason')
    let other_reason_checkbox = $('.check-disable-reason.check-other-reason')
    let has_any_checkbox_checked = checkboxes.map(function () { return $(this).is(":checked") }).toArray().includes(true)
    let input = $('#input-reason')
    let submit = $('#submit-disable-listing')

    let toggleCheckboxes = function () {
      $(checkboxes).each(function () {
        if ($(target)[0] != $(this)[0]) {
          $(this).prop("checked", false)
        }
      })
    }
    toggleCheckboxes()

    let enableSubmit = function () {
      submit.attr('type', 'submit')
      submit.addClass('btn-primary')
      submit.removeClass('btn-secondary')
      submit.removeClass('disabled')
    }
    let disableSubmit = function () {
      submit.attr('type', 'button')
      submit.removeClass('btn-primary')
      submit.addClass('btn-secondary')
      submit.addClass('disabled')
    }
    let enableInput = function () {
      input.removeClass('d-none')
      input.attr('required', 'required')
    }
    let disableInput = function () {
      input.addClass('d-none')
      input.removeAttr('required')
      input.val('')
    }

    if (has_any_checkbox_checked && $(other_reason_checkbox).is(":checked")) {
      enableInput()
      enableSubmit()
    }
    else if (has_any_checkbox_checked && !$(other_reason_checkbox).is(":checked")) {
      disableInput()
      enableSubmit()
    } else {
      disableInput()
      disableSubmit()
    }

  },
}

export default component
