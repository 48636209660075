import { Component } from "@page/component"

const component = new Component()
const urlParams = new URLSearchParams(window.location.search)

component.expose = {
    checkForModalPupUp() {
        let _modal = urlParams.get('modalShow')

        if(_modal){
            $(`#${_modal}`).modal('toggle')
        }
    },

    changeBoxView(e){
        let _boxToActive = $(e).data('boxtoactive')
        let _boxStatus = $(e).data('status')
        let _boxes = ['contact-box', 'document-box', 'visit-box', 'contract-box']

        _boxes = _boxes.filter(item => item !== _boxToActive)
        $('#float-box').removeClass(_boxes.join(' '))

        if(_boxStatus === 'locked'){
            $(`#${_boxToActive}-locked-modal`).modal('toggle')
        } else {
            $('#float-box').toggleClass(_boxToActive)
        }
    },

    closeBox(e) {
        let container = $(".changeBox");
        let box = $('.z-float-box')
        let _boxes = ['contact-box', 'document-box', 'visit-box', 'contract-box']
        
        if(!box.is(e.target) && container.has(e.target).length === 0 && $(e.target).parents("div.z-float-box").length === 0 && !container.is(e.target) && container.has(e.target).length === 0){
            $('#float-box').removeClass(_boxes.join(' '))
        }
    },

    displayInformation(values){
        let _info = $(values).data('info')
        let _value = $(values).data('value')

        $('#show-info')[0].innerHTML = `${_info}:<br/> ${_value}`
    }
}

export default component
