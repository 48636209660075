import { Component } from "@page/component"
import Chocolat from 'chocolat' // chocolat 1.0.0
import flatpickr from "flatpickr"

const component = new Component()

component.start = async function() {
  require('jq-sticky-anything/jq-sticky-anything')
  $.fn.Chocolat = Chocolat
}

component.ready = function() {
  initGallery()
  showPhone()

  if($('.remove-nested').length === 1){
    $('.remove-nested').hide()
  }

  $('.btn-flip').on('click', function(e) {
    e.preventDefault()
    $('.card-flip').toggleClass('flipped')
    $('#form-contact').toggleClass('d-none')
    $('#contact_name').focus()
  })

  // sticky card
  $( '.produto-valor' ).stickThis({
    top:            28,
    minscreenwidth: 992,
    zindex:         889,
    adminbar:       1
  })
  
  initVisitForm()
}

component.expose = {
  initGallery() {
    Chocolat(document.querySelectorAll('.chocolat-image'), {})
  },

  showPhone(){
    $('#phone-link').on('click', function(e) {
      e.preventDefault()
      $('#landlord-phone').toggleClass('hidden-phone')
      $('#phone-link').toggleClass('d-none')
    })
  },

  initVisitForm() {
    $('.links').on('cocoon:after-insert', function() {
      flatpickrDateNil()
      
      if($('.nested-fields').length >= 3){
        $('.links').hide()
      }

      if($('.remove-nested').length > 1){
        $('.remove-nested').show()
      }
    })
  
  
    $('.nested').on('cocoon:after-remove', function() {
      if($('.nested-fields').length < 3){
        $('.links').show()
      }

      if($('.remove-nested').length === 1){
        $('.remove-nested').hide()
      }
    })
  }
}

export default component
