import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    flatpickrDateNil()
    applyMasks()
    initVisitForm()
  }

  disable_btns() {
    this.element.querySelectorAll("[type=submit]").forEach(e => {
      e.disabled = true
    })
  }

  enable_btns() {
    this.element.querySelectorAll("[type=submit]").forEach(e => {
      e.disabled = false
    })
  }

  reset() {
    this.element.reset()
    this.enable_btns()
  }
}