import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  $('#lease_issuer_start_date, #contract_period').on('change', function() {
    let months = $('#contract_period').val()
    if (months !== '') {
      let start_date = $('#lease_issuer_start_date').val()
      let end_date = moment(start_date, "YYYY-MM-DD").add(months, 'months').format('YYYY-MM-DD')
      $('#lease_issuer_end_date').val(end_date)
      flatpickrDate()
    }
  })
}

export default component
