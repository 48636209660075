import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  fileNameOnInput()
  showInput()

  $('.validate-input-checks').on('change', function(){
    changeButtonStatus()
  })

  $('.validate-input').on('change', function(){
    changeButtonStatus()
  })

  $(document).on('DOMSubtreeModified', function(){
    changeButtonStatus()
    $('.validate-input').on('change', function(){
      changeButtonStatus()
    })
  })

  $("#upload").change(function () {
    if (this.files && this.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        $('#image_upload').attr('src', e.target.result)
      }
      reader.readAsDataURL(this.files[0])
    }
  })

  $(".input-upload").click(function(){
    $('#upload').trigger('click')
  })

  $('#current_renter').click(function() {
    $(".is-renter").show()
    $(".is-renter-input").prop( "checked", true )
  })

  $('#not_renter').click(function() {
    $(".is-renter").hide()
    $(".is-renter-input").prop( "checked", false )
  })
}

component.expose = {
  fileNameOnInput() {
    $(document).on('change', '.input-upload-doc', function(e) {
      let input = $(e.target)

      if ($(input)[0].files.length > 0) {
        let text = $(input).closest('.row').find('select option:selected').text()
        $(input).prev('label').text(text)

        $(input).closest('.row').find('.document').toggle('d-none')
        $(input).closest('.row').find('.doc-select').toggleClass('d-none')
        $(input).prev('label').toggleClass('disabled bg-secondary')
      }
    })
  },

  showInput() {
    $(document).on('change', '.document', function(e) {
      let _target = $(e.target)
      $(_target).parent().parent().find('.doc-remove').addClass('d-none')
      $(_target).parent().parent().find('.doc-target').removeClass('d-none')
    })
  },

  changeButtonStatus() {
    let _valid = true
    let checkeds = $('.checkbox-required').find('input:checked').length

    $('.validate-input').each(function(e){
      if($(this).val() == '' && $(this).is(':visible')){
          _valid = false
      }
    })

    if(checkeds < 1){
      _valid = false
    }

    if(_valid === true){
      if($('.doc-target').is(':visible')){
        $('.submit-input').removeClass('disabled btn-secondary')
        $('.submit-input').addClass('btn-primary')
      } else {
        $('.submit-input').removeClass('btn-primary')
        $('.submit-input').addClass('disabled btn-secondary')
      }
    } else {
      $('.submit-input').removeClass('btn-primary')
      $('.submit-input').addClass('disabled btn-secondary')
    }
  }
}

export default component
