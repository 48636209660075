import { Component } from "@page/component"

const component = new Component({
  mixins: ['app/landlords/rents/leases/tabs/_transactions.js']
})

const urlParams = new URLSearchParams(window.location.search)

component.start = function() {
  window.addEventListener('load', function () {
    if(urlParams.get('modal') === 'change_lease'){
      $('#change-lease-modal').modal('toggle')
    }
  })
}

export default component
