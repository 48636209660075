// This component depends of Dropzone, Compressor and jquery-ui-sortable
import 'jquery-ui-sortable-npm'
import * as Compressor from 'compressorjs/compressorjs'
import * as Dropzone from '@stisla/node_modules/dropzone/dist/dropzone'

Dropzone.autoDiscover = false
Dropzone.prototype.defaultOptions.dictInvalidFileType = "Tipo de arquivo inválido";
Dropzone.prototype.defaultOptions.dictFileTooBig = "Arquivo muito grande";
Dropzone.prototype.defaultOptions.dictUploadCanceled = "Upload não realizado";
Dropzone.prototype.defaultOptions.dictRemoveFile = "Remover";
Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "Excedeu o limite de arquivos";

const minFiles = 3, maxFiles = 20;

export class DropzoneHandler {
  constructor(){
    this.$element = $("#listingDropzone")
    if(!this.$element.length)
      throw "Dropzone Element not found."

    this.propertyId = this.$element.data().propertyId
    this.dropzone = undefined
    this.dropzoneOptions = {
      url: `/app/sales/properties/${this.propertyId}/listing/upload_images?`,
      method: "post",
      uploadMultiple: true,
      parallelUploads: 5,
      autoQueue: true,
      autoProcessQueue: true,
      maxFiles: maxFiles,
      maxFilesize: 10, //megabytes
      acceptedFiles: "image/gif,image/png,image/jpeg,image/bmp",
      transformFile(file, successCallback, errorCallback) {
        new Compressor(file, {
          checkOrientation: true,
          maxWidth: 8192,
          maxHeight: 8192,
          quality: 0.6,
          convertSize: 0,
          success: (compressedFile) => { successCallback(compressedFile) },
          error: (err) => { throw err },
        })
      }
    }

    this.build()
  }

  build(){
    let handler = this

    new Dropzone(handler.$element.get(0), {
      ...handler.dropzoneOptions,
      dictDefaultMessage: "<div class='img-wrap ratio-16-10' id='dictDefaultMessage'> <div class='img-content'><img class='d-block w-100 h-100 size-m-adjustment' src='/img/news/placeholder.png' alt='Image'></div></div>",
      previewsContainer: "#dropzone-previews",
      clickable: $('#clickable-area-plus')[0],
      addRemoveLinks: true,

      init() {
        handler.dropzone = this
        handler.fetchImages()
        handler._customizeClickableArea()

        // --- listening dropzone events ---
        // stage 1: adding files
        handler.dropzone.on("addedfile", (file) => handler._handleChanges({skipValidate: true}) )
        handler.dropzone.on("removedfile", (file) => handler._handleChanges() )
        handler.dropzone.on("addedfiles", (files) => handler._handleChanges() )
        handler.dropzone.on("maxfilesexceeded", (files) => handler._onMaxFilesExceed() )
        handler.dropzone.on("maxfilesreached", (files) => handler._onMaxFilesReached() )

        // stage 2: uploading files
        handler.dropzone.on("processing", (file) => handler._disableSubmit() )
        handler.dropzone.on("queuecomplete", (file) => handler._enableSubmit() )
        handler.dropzone.on("canceledmultiple", (files) => handler._notifyInBugsnag(files) )
        handler.dropzone.on("successmultiple", (files, response) => {
          handler._handleChanges()
          handler._setFilesIds(files, response)
        })

        // --- adding sortable ability ---
        $('.sortable').sortable({ stop: ()=> handler._sortFiles() })
      }
    })
  }

  fetchImages() {
    const handler = this
    return $.get(`/app/sales/properties/${handler.propertyId}/listing/fetch_images`, function(images) {
      if(images !== undefined && images.length) {
        $.each(images, function(key, img) {
          img['accepted']= true
          img['status']= 'success'
          handler.dropzone.emit("addedfile", img)
          handler.dropzone.emit("thumbnail", img, img.url)
          handler.dropzone.emit("complete", img)
          handler.dropzone.files.push(img)
          $(img.previewElement).attr('data-id', img.id)
        })
        handler.dropzone.emit("addedfiles", handler.dropzone.files)
      }
    })
  }

  commitImages() {
    const handler = this
    const ids = handler.dropzone.files.map((f) => f.id)
    return $.post(`/app/sales/properties/${handler.propertyId}/listing/commit_images`, { 'images[]': ids })
  }

  _customizeClickableArea(){
    const $area = $("#clickable-area-plus")
    const $dictDefaultMessage = $("#dictDefaultMessage")
    $dictDefaultMessage.parent('button.dz-button').addClass('unclickable')
    $area.css('cursor', 'pointer')
    $area.click(() => $dictDefaultMessage.click() )
  }

  _disableClickableArea(){
    const $area = $("#clickable-area-plus")
    $area.removeClass('active-plus-drop')
    $area.addClass('desactivated-plus-drop unclickable')
  }

  _enableClickableArea(){
    const $area = $("#clickable-area-plus")
    $area.removeClass('desactivated-plus-drop unclickable')
    $area.addClass('active-plus-drop')
  }

  _handleChanges(args={}){
    // toogle default message
    const $dictDefaultMessage = $("#dictDefaultMessage")
    if ($dictDefaultMessage.length)
      (this.dropzone.files.length > 0) ? $dictDefaultMessage.hide() : $dictDefaultMessage.show()

    // perform validation
    if ( !args['skipValidate'] === true)
      this.validate()
  }

  validate(){
    // just look to queued or uploaded files
    const files = this.dropzone.files.filter(f => (f.status === 'success'))

    if(files.length === maxFiles){
      this._onMaxFilesReached()
      return true
    }
    else if (files.length < minFiles){
      this._onMinFilesNotReached()
      return false
    }
    else if(files.length > maxFiles){
      this._onMaxFilesExceed()
      return false
    }
    else {
      this._hideErrorMessage()
      this._enableClickableArea()
      return true
    }
  }

  _onMinFilesNotReached(){
    this._showErrorMessage()
    this._enableClickableArea()
  }

  _onMaxFilesReached(){
    this._hideErrorMessage()
    this._disableClickableArea()
  }

  _onMaxFilesExceed(){
    this._showErrorMessage()
    this._disableClickableArea()
  }

  _showErrorMessage(){
    $('.text-danger').removeClass( "d-none" )
  }

  _hideErrorMessage(){
    $('.text-danger').addClass( "d-none" )
  }

  _disableSubmit(){
    $('#listing-submit-5').addClass('disabled')
  }

  _enableSubmit(){
    $('#listing-submit-5').removeClass('disabled')
  }

  _notifyInBugsnag(files){
    try {
      window.Bugsnag.notify(new Error('Upload canceled by dropzone'), function (event) {
        event.severity = 'info'
        event.context = 'Dropzone: User adding photos)'
        event.addMetadata('files', files)
      })
    } catch (e) {
      console.log('Bugsnag.notify could not be performed')
    }
  }

  _setFilesIds(files, response){
    files.forEach(function(file, idx){
      file.id = response[idx].id
      $(file.previewElement).attr('data-id', file.id)
    })
  }

  _sortFiles(){
    const handler = this
    let files = []
    handler.$element.find('.dz-preview').each(function(idx, dzPreview) {
      handler.dropzone.files.forEach(function(file) {
        if (file.id === $(dzPreview).data('id'))
          files.push(file)
      })
    })
    handler.dropzone.files = files
  }
}
