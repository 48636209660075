import { Component } from "@page/component"
import { DataLayerManager } from "@lib/data_layer/manager"
import consumer from "@channels/consumer"

const component = new Component()

component.ready = function(){
  window.dataLayerManager = new DataLayerManager()
  window.dataLayerManager.setup().then(function(){

    // 1. push event 'page_loaded'
    // window.dataLayerManager.push({event: 'page_loaded'})

    // 2. listen '.data layer' elements
    let elements = $('.data-layer,.data_layer')

    if(elements.length) {
      $(elements).each(function() {
        let element = $(this)
        let listen = element.data('listen') || 'click'
        let event = element.data('event')
        let debug = element.data('debug')

        $(element).on(listen, function() {
          if (debug) console.log('[DataLayer] event:', event)
          window.dataLayerManager.push({event})
        })
      })
    }

    // 3. subscribe in data_layer channel
    let fetched = false

    consumer.subscriptions.create("DataLayerChannel", {
      connected() {
        if(!fetched) {
          // Calls `DataLayerChannel#fetch_tags` on the server.
          this.perform("fetch_tags")
          fetched = true
        }
      },
      received(data) {
        for (let tag of data) {
          window.dataLayerManager.push(tag.data)
          // Calls `DataLayerChannel#update(data)` on the server.
          this.perform("update", {id: tag.id, status: 'sent'})
        }
      }
    })

  })
}

export default component
