import { Component } from "@page/component"
import * as cpf from '@fnando/cpf'

const component = new Component()

component.ready = function() {
  formPreventLeave()
}

component.expose = {
  cpf,

  validates() {
    if(cpf.isValid($(".cpf-field").val()))
      $(".cpf-field")[0].setCustomValidity("")
    else
      $(".cpf-field")[0].setCustomValidity('CPF inválido')
  },

  formPreventLeave() {
    let form = $('form.prevent-leave')
    if ($(form).length){
      let form_before = $(form).serialize()
      let form_submit = false
      $(form).submit(function(){ form_submit = true })

      window.onbeforeunload = function(event) {
        if( !form_submit && (form_before !== $(form).serialize()) ) {
          // this forces the browser to display an alert to confirm
          event['returnValue'] = undefined
          return undefined
        }
      }
    }
  }
}

export default component
