import { Component } from "@page/component"

import Chocolat from 'chocolat' // chocolat 1.0.0
import * as ClipboardJS from "clipboard"

const component = new Component()

component.start = function() {
  $.fn.Chocolat = Chocolat
}

component.ready = function() {
  initGallery()
  initClipboard()

  $('.switch_listing_status').on('click', function(e){
    e.preventDefault();
    let _switch = $(e.target)

    Loading.show()
    changeListingStatus(_switch.data('id')).done(function() {
        window.location.href = `/app/sales/properties/${_switch.data('property-id')}`
    })
  })
}

component.expose = {
  initGallery() {
    Chocolat(document.querySelectorAll('.gallery-desktop-image'), {})
    Chocolat(document.querySelectorAll('.gallery-mobile-image'), {})
  },

  initClipboard() {
    const clip = new ClipboardJS('.jscopy');
    clip.on("success", function () {
      iziToast.success({
        title: 'Sucesso!',
        message: 'Link copiado.',
        position: 'topRight'
      })
    })
  },

  changeListingStatus(listing_id) {
    return $.ajax({ method: 'GET', url: `/app/sales/properties/listings/${listing_id}/change_listing_status` })
  }
}

export default component
