import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  const params = new URLSearchParams(window.location.search)

  setTimeout(function(){
    if (params.has('_callback')) {
      for (const callback_function of params.getAll('_callback')) {
        eval(callback_function)()
      }
    }
  }, 300)

}

export default component
