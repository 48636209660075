import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:before-fetch-response', (event) => {
      Loading.hide();
    });

    document.addEventListener('turbo:before-fetch-request', (event) => {
      Loading.show()
    });
  }

  disconnect(){
    //Loading.hide();
  }
}