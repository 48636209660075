import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if ( !this.element.dataset.allowedPaths.includes(window.location.pathname) ) {
      $(this.element).modal('show')
    }
  }

  disconnect() {
    $(this.element).modal('hide')
  }
}