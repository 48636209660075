import { Component } from "@page/component"

const component = new Component({
  mixins: [
    'app/landlords/rents/leases/_step_1.js',
    'app/landlords/rents/leases/_step_2.js',
    'app/landlords/rents/leases/_step_3.js',
    'app/landlords/rents/leases/_step_4.js',
    'app/landlords/rents/leases/_step_5.js',
  ]
})

component.ready = function() {
  // cocoon
  $(".add-fields").on('click', function(){
    setTimeout(applyMasks, 0)
  })

  applyMasks()
}

export default component
