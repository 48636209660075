import { Component } from "@page/component"
import Shepherd from 'shepherd.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const component = new Component()

component.expose = {
  callTour1() {

    // build tour
    const tour = new Shepherd.Tour({useModalOverlay: true})
    tour.on("start", ()=> disableBodyScroll(document.body));
    tour.on("cancel", ()=> clearAllBodyScrollLocks());
    tour.on("complete", ()=> clearAllBodyScrollLocks());

    tour.addStep({
      id: 'step-1-view',
      title: 'Etapa 1',
      text: `
        <div class="row">
            <div class="col d-flex">
                <a class="btn z-btn-circle-sm unclickable unlocked" data-status="unlocked" style="min-width: 54px">
                    <i class="fas fa-phone-alt fa-2x"></i>
                </a>
                <span class="ml-4">Clique nesse ícone para entrar em contato com o interessado.</span>
            </div>
        </div>
    `,
      arrow: true,
      scrollTo: true,
      scrollToHandler: (window.isMobile() ? window.scrollItOnBottom : undefined),
      attachTo: {
        element: '#stage-1',
        on: 'bottom'
      }
    })

    // add custom close
    $("#stage-1").on('click', tour.cancel)

    // check if tour need be displayed
    Rails.ajax({
      type: 'GET',
      url: `/app/landlords/tours/check_user_tour`,
      data: new URLSearchParams({ tour_name: `lease_approach_contact_stage_1` } ).toString(),
      dataType: 'json',
        success: (data) => {
          if (data["show_tour"]) {
            tour.start()
          }
        }
    })
  }
}

export default component
