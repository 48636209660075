import { Component } from "@page/component"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import moment from "moment"

const component = new Component()

component.ready = function(){
  flatpickrDate()
  flatpickrDateTime()
  flatpickrDateTimeRounded()
  flatpickrDateNil()
}

component.expose = {
  flatpickrDate() {
    let elements = $('.flatpickr-date')
    if(elements.length) {
      $(elements).each(function(){
        let input = $(this)
        let value = $(input).val()
        let date = (value != '' && moment(value)._isValid) ? moment(value).toDate() : new Date()

        if(!$(input).hasClass('input')){
          flatpickr($(input), {
            locale: Portuguese,
            defaultDate: date,
            enableTime: false,
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d/m/Y",
          })
        }
      })
    }
  },

  flatpickrDateTime() {
    let elements = $('.flatpickr-datetime')
    if(elements.length) {
      $(elements).each(function(){
        let input = $(this)
        let value = $(input).val()
        let date = (value != '' && moment(value)._isValid) ? moment(value).toDate() : moment().toDate()

        if(!$(input).hasClass('input')){
          flatpickr($(input), {
            enableTime: true,
            locale: Portuguese,
            defaultDate: date,
            dateFormat: "Y-m-d H:i",
            altInput: true,
            altFormat: "d/m/Y H:i",
          })
        }
      })
    }
  },

  flatpickrDateTimeRounded() {
    let elements = $('.flatpickr-datetime-rounded')
    if(elements.length) {
      $(elements).each(function(){
        let input = $(this)
        let value = $(input).val()
        let date = (value != '' && moment(value)._isValid) ? moment(value).toDate() : moment().toDate()
        date = date.setMinutes('00')

        if(!$(input).hasClass('input')){
          flatpickr($(input), {
            enableTime: true,
            locale: Portuguese,
            dateFormat: "Y-m-d H:i",
            altInput: true,
            altFormat: "d/m/Y H:i",
          })
        }
      })
    }
  },

  flatpickrDateNil() {
    let elements = $('.flatpickr-date-nil')
    if(elements.length) {
      $(elements).each(function(){
        let input = $(this)

        if(!$(input).hasClass('input')){
          flatpickr($(input), {
            locale: Portuguese,
            defaultDate: '',
            enableTime: false,
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d/m/Y",
          })
        }
      })
    }
  },
}

export default component
