const meta = document.getElementsByName('data_layer')[0]

const fetchUser = function() {
  try{
    return JSON.parse(meta.getAttribute('user'))
  } catch (e) {
    return null
  }
}

export class DataLayerManager {
  constructor() {
    this.user = fetchUser()
  }

  // merge user data and push
  push(tag) {
    window.dataLayer.push({...tag, ...this.user});
  }

  // set window.dataLayer if not defined (after 3 seconds) and push all tags
  setup() {
    return new Promise((resolve, reject) => {
      let counter = 0;

      const setDataLayer = function() {
        if( (typeof window.dataLayer == "undefined") && (counter < 10) ) {
          counter += 1;
          setTimeout(setDataLayer, 300);
        } else {
          window.dataLayer = window.dataLayer || [];
          resolve()
        }
      }

      setDataLayer()
    })
  }
}
