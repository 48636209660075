import { Component } from "@page/component"

const component = new Component()
const urlParams = new URLSearchParams(window.location.search);

component.ready = function () {
  $('#inspectionAction').on('click', function(e) {
    e.preventDefault()
      $('#inspection_modal').modal('toggle')
  })
}

export default component
