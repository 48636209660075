import { Component } from "@page/component"

const component = new Component({
  mixins: ['app/public/visit_feedbacks/_form.js']
})

component.ready = function() {
  // watch visit radio buttons
  $('input[name="visit_feedback[visited]"]').on('change', function() {
    if ($(this).is(":checked") && $(this).val() === "true"){
      window.dBlock("#want_to_rent")
      window.enableSubmit()
    }
    else {
      window.dNone("#want_to_rent")
      window.enableSubmit()
      $('label[for="want_to_rent_false"]').click()
    }
  })

  // watch want_to_rent radio buttons
  $('input[name="visit_feedback[want_to_rent]"]').on('change', function() {
    window.enableSubmit()
  })
}

export default component
