import { Component } from "@page/component"

const component = new Component()

component.ready = function () {
  $("#upload").change(function () {
    if (this.files && this.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        $('#image_upload').attr('src', e.target.result)
      }
      reader.readAsDataURL(this.files[0])
    }
  })

  $(".input-upload").click(function(){
    $('#upload').trigger('click')
  })

  $("#change-password").on('change', function(){
    togglePasswordContainer()
  })
  togglePasswordContainer()
}

component.expose = {

  togglePasswordContainer() {
    let container = $('#change-password-container')
    let checkbox = $('#change-password')
    if (checkbox.is(":checked")) {
      container.show()
    } else{
      container.hide()
      container.find('input[type="password"]').val('')
    }
  }
}

export default component
