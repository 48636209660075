import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  adjustToLongEmail()

  $(document).on('click', '.tab-link', function(e){
    $($(e.target).attr('href')).tab('show')
    if($(e.target).attr('href') == '#tab-profile3'){
      $('#closeModalButton').removeClass('d-none')
    }
  })
}

component.expose = {
  adjustToLongEmail() {
    let email = $('#email-adjustment').text()
    if(email.length > 20){
      $('#email-adjustment').addClass('text-break')
      email = email.split('@')
      $('#email-adjustment').text(`${email[0]}@ ${email[1]}`)
    }
  },

  showSuccessApply() {
    $('#success-apply').modal('toggle')
  }
}

export default component
