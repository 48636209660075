export class Component {
  constructor(object) {
    let key    = ''
    let loaded = false
    let mixins = []
    let start  = ()=>{}
    let ready  = ()=>{}
    let expose = {}

    if (object) {
      this.key    = object.key    || key
      this.loaded = object.loaded || loaded
      this.mixins = object.mixins || mixins
      this.start  = object.start  || start
      this.ready  = object.ready  || ready
      this.expose = object.expose || expose
    }
    else{
      this.key    = key
      this.loaded = loaded
      this.mixins = mixins
      this.start  = start
      this.ready  = ready
      this.expose = expose
    }
  }
}
