import { Component } from "@page/component"

const component = new Component()

component.ready = function() {

  function disable_btn(e) {
    $(e).find(':input[type=submit]').prop('disabled', true);
  }

  function enable_btn(e) {
    $(e).find(':input[type=submit]').prop('disabled', false);
  }

  document.querySelectorAll('form').forEach(e => {
    var data_prevent_double_click = e.getAttribute("data-prevent-double-click");

    if ( data_prevent_double_click == 'false' ) {
      return;
    }

    e.addEventListener("submit", function() {
      disable_btn(e);

      setTimeout(function(){
        enable_btn(e);
      }, 5000);
    }, false);
  });
}

export default component
