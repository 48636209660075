import {Component} from "@page/component"

const component = new Component({
  mixins: [
    'app/landlords/lease_approaches/contacts/_tour_lease_approach_contact_stage_1.js',
    'app/landlords/lease_approaches/contacts/_tour_lease_approach_contact_stage_2.js',
    'app/landlords/lease_approaches/contacts/_tour_lease_approach_contact_stage_3.js',
    'app/landlords/lease_approaches/contacts/_tour_lease_approach_contact_stage_4.js',
    'app/landlords/lease_approaches/shared/_helper.js'
  ]
})

component.ready = function () {
  checkForModalPupUp()

  $('#chain-observations-form').on('click', function (e) {
    e.preventDefault()
    changeObservation('form-observations', 'observations')
    $('#contact_internal_observations').focus()
  })

  $('#hide-form').on('click', function (e) {
    e.preventDefault()
    changeObservation('observations', 'form-observations')
  })

  $('#display-message-change').on('click', function (e) {
    e.preventDefault()
    $('.display-message').toggle('d-none')
  })

  $('.display-info').on('click', function () {
    displayInformation(this)
  })

  $('.transient-modal').on('click', function () {
    $('#contact-locked-modal').modal('hide')
    $('#contact-info-modal').modal('toggle')
  })

  $('.changeBox').on('click', function (e) {
    e.preventDefault()
    changeBoxView(this)
  })

  $(document).on('click', function (e) {
    closeBox(e)
  })

  // Tour (shepherd): fire tour for current stage
  // window.callTour()

  // Tour (modal): open tour when click in link
  $('#open-stages-step-by-step-modal').on('click', function(e) {
    e.preventDefault()
    $('#tab-link1').tab('show')
    $('#stages-step-by-step-modal').modal('toggle')
  })
  // navigation in modal pills
  $(document).on('click', '.tab-link', function(e){
    e.preventDefault()
    $($(e.target).attr('href')).tab('show')
  })
}

component.expose = {
  changeObservation(fieldToHide, fieldToShow) {
    $(`#${fieldToHide}`).toggle('d-none')
    $(`#${fieldToShow}`).toggle('d-none')
  },

  callTour(){
    const _callTour = () =>{
      let current_stage = $('#stages').data('current-stage')
      eval(`callTour${current_stage}`)()
    }

    // wait modal close
    const modal = $("#contact-locked-modal")
    const modalLandlord = $("#landlord-verification-modal")
    if (modal.data('bs.modal')?._isShown || modalLandlord.data('bs.modal')?._isShown)
      modal.on('hidden.bs.modal', _callTour )
    else
      _callTour()
  }
}

export default component
