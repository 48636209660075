const masked = function(value) {
  return $(".currency").masked(value.toFixed(2))
}

const number = function(value) {
  value = value.toString().trim()
  if(value === '')
    return Number(value)
  else
    return parseFloat(value.replace(/\./g, '').replace(/,/g, '.'))
}

export class Step3Handler {
  constructor() {
    this.$form = $('#listing_form')
    this.$back_button = $('input[name="back"]')
    this.$submit_button = $('#listing-submit-3')

    this.$input_rental_price = $('#listing_virtual_rental_price')
    this.$input_rental_price_hidden = $('#listing_rental_price')
    this.$input_condo_fee = $('#listing_condo_fee')
    this.$input_iptu_price = $('#listing_iptu_price')
    this.$input_total_price_hidden = $('#hdnTotal')
    this.$preview_total_price = $('#spanTotal')

    this.$input_insurance_offered = $('#listing_insurance_offered')
    this.$input_insurance_accepted = $('#listing_insurance_accepted')
    this.$input_insurance_percentage = $('#listing_insurance_percentage')
    this.$input_insurance_price = $('#listing_insurance_price')
    this.$modal_insurance = $('#insurance-offer-modal')
    this.$preview_total_with_insurance_price = $('#total_with_insurance')
    this.$preview_insurance_price = $('#total_insurance')
    this.$accept_insurance_button = $('#accept_insurance')
    this.$reject_insurance_button = $('#reject_insurance')

    if(!this.$input_rental_price.length > 0)
      return

    this.init()
  }

  init() {
    const self = this

    // we have a virtual field to put the rental price, the real field will be hidden
    self.setupRentalPriceInput()

    // display insurance price field when is editing
    if (self.insuranceIsAccepted()) {
      // self.showInsurancePrice()
    }

    // preview the total price
    self.updateTotalPricePreview()

    // listen changes in fields and update total price preview
    self.$input_rental_price.on('keyup keydown change', () => {
      self.updateTotalPricePreview()
      self.updateInsurancePriceInput()
    })
    self.$input_condo_fee.on('keyup keydown change', () => self.updateTotalPricePreview())
    self.$input_iptu_price.on('keyup keydown change', () => self.updateTotalPricePreview())

    // enable real time validation
    self.$form.validator({disable: false, delay: 0})

    // on click to submit
    self.$submit_button.on('click', function (e) {
      if (!self.$form.validator('validate').has('.has-error').length) {
        e.preventDefault()
        if (self.$input_insurance_offered.val() === "false")
          // self.launchInsuranceModal()
          self.submit()
        else
          self.submit()
      }
    })

    // on click to back
    self.$back_button.on('click', function (e) {
      self.$form.validator('destroy')
      self.$form.attr('novalidate', 'novalidate')
    })
  }


  // Functions that perform changes in the DOM

  showInsurancePrice() {
    this.$input_insurance_price.closest('.form-group').removeClass('d-none')
  }

  setupRentalPriceInput() {
    if (this.insuranceIsAccepted()) {
      let price = number(this.$input_rental_price_hidden.val()) - number(this.$input_insurance_price.val())
      this.$input_rental_price.val(masked(price))
    } else {
      this.$input_rental_price.val(this.$input_rental_price_hidden.val())
    }
  }

  updateTotalPricePreview() {
    this.$preview_total_price.text(masked(this.sumTotalPrice()))
  }

  updateInsurancePricePreview() {
    this.$preview_insurance_price.text(masked(this.calculateInsurancePrice()))
    this.$preview_total_with_insurance_price.text(masked(this.sumTotalPrice({include_insurance_price: true})))
  }

  updateInsurancePriceInput() {
    if (this.insuranceIsAccepted())
      this.$input_insurance_price.val(masked(this.calculateInsurancePrice()))
    else
      this.$input_insurance_price.val('')
  }

  launchInsuranceModal() {
    const self = this
    self.$input_insurance_offered.val('true')
    self.$modal_insurance.modal('toggle')

    // set values in insurance preview
    self.updateInsurancePricePreview()

    // on click accept insurance
    self.$accept_insurance_button.on('click', () => {
      // set fields values
      self.$input_insurance_accepted.val(true)
      self.$input_insurance_percentage.val(self.getInsurancePercentage())
      self.updateInsurancePriceInput()
      self.submit()
    })

    // on click reject insurance
    self.$reject_insurance_button.on('click', () => {
      // clear fields values
      self.$input_insurance_accepted.val(false)
      self.$input_insurance_percentage.val('')
      self.updateInsurancePriceInput()
      self.submit()
    })
  }

  submit(){
    this.updateRentalPriceHiddenInput()
    this.updateTotalPriceHiddenInput()
    this.$form.submit()
  }

  updateRentalPriceHiddenInput() {
    if (this.insuranceIsAccepted()) {
      let price = number(this.$input_rental_price.val()) + number(this.$input_insurance_price.val())
      this.$input_rental_price_hidden.val(masked(price))
    } else {
      this.$input_rental_price_hidden.val(this.$input_rental_price.val())
    }
  }

  updateTotalPriceHiddenInput(){
    this.$input_total_price_hidden.val(masked(this.sumTotalPrice()))
  }


  // Functions to calculate

  sumTotalPrice(args = {}) {
    let total = 0
    total += number(this.$input_rental_price.val())
    total += number(this.$input_condo_fee.val())
    total += number(this.$input_iptu_price.val())

    if (args['include_insurance_price'] === true || this.insuranceIsAccepted())
      total += this.calculateInsurancePrice()
    return total
  }

  calculateInsurancePrice() {
    return number(this.$input_rental_price.val()) * (this.getInsurancePercentage() / 100)
  }

  getInsurancePercentage() {
    let percentage_default = this.$input_insurance_percentage.data('insurance-percentage-default'),
        percentage_actual = this.$input_insurance_percentage.val(),
        percentage = (percentage_actual != '') ? percentage_actual : percentage_default
    return parseFloat(percentage)
  }


  // Functions misc

  insuranceIsAccepted() {
    return (this.$input_insurance_accepted.val() === "true")
  }

}
