import { Component } from "@page/component"

const component = new Component({
  mixins: [
    'app/landlords/lease_issuer/_step_1.js',
    'app/landlords/lease_issuer/_step_2.js',
    'app/landlords/lease_issuer/_step_3.js',
  ],
})

component.ready = function() {
  if($('.checkbox-required')[0] !== undefined)
    validatePaymentMethod()

  $('.checkbox-required').on('change', function(){
    validatePaymentMethod()
  })
}

component.expose =  {

  validatePaymentMethod() {
    let payment_method = $('#payment_methods_cash')[0]
    if ($('.checkbox-required :checkbox:checked').length === 0) {
      payment_method.setCustomValidity("Preencha algum tipo de pagamento")
    } else {
      payment_method.setCustomValidity("")
    }
  },
}

export default component
