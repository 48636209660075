class PropertiesList {
  constructor(input, button, container) {
    this.input = $(input)
    this.button = $(button)
    this.container = $(container)
    this.autocomplete = {}
    if(this.input && this.button && this.container)
      this.configureListeners()
  }

  configureListeners(){
    let self = this
    // submit
    $(self.input).on("keydown", function(event) {
      if(event.which == 13)
        self.search()
    })
    $(self.button).on("click", function(event) {
      self.search()
    })
  }

  configureGoogleAutocomplete(google_autocomplete){
    let self = this
    self.autocomplete = google_autocomplete
    self.autocomplete.addListener('place_changed', function(){
      let place = self.autocomplete.getPlace()
      self.search(place.name)
    })
  }

  search(search) {
    let self = this
    let target = $(self.input).data('url')
    let q = search || $(self.input).val()

    $.ajax({
      method: 'GET',
      url: target,
      data: {q},
      dataType: "html"
    }).done(function(response) {
      $(self.container).html(response)
    }).fail(function(error) {
      console.log(error)
    })
  }
}

export { PropertiesList }
