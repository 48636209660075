import { Component } from "@page/component"
import {FormHandler} from "../landlords/properties/components/form_handler";

const component = new Component()

var autocomplete,
    searchInput = 'autocomplete';

component.ready = function() {
  window.waitGoogleApi().then(()=>{
    initMap()
  })

  $(".search-filter").on('change', function(){
    submitSearchForm()
  })

  $("select[name='order_by']").on('change', function(){
    $("input[name='search[order]']").val($(this).val())
    submitSearchForm()
  })

  $("#reset-filter").on('click', function(){
    $('.search-filter').each(function(){
      clearField($(this))
    })
    $("select[name='order_by']").val('')

    toogleFilters('hide')
  })

  $('.search-field-clear').on('click', function(){
    let field_id = $(this).data('field-id')
    let field_name = $(this).data('field-name')
    if (!!field_id)
      clearField($(`#${field_id}`))
    else
      clearField($(`[name='${field_name}']`))
  })

  $('#filters-toggle').on('click', function(){
    $(this).hide()
    toogleFilters('show')
  })

  $('span.js-link').on('click', function(){
    redirectTo($(this).data('href'), $(this).data('target'))
  })
}

component.expose = {
  waitGoogleApi(){
    return new Promise((resolve, reject)=>{
      let counter = 0
      const resolvePromisseOrWait = function() {
        counter += 1
        if( window.google )
          resolve()
        else if (counter <= 20)
          setTimeout(resolvePromisseOrWait, 250)
        else
          reject()
      }
      resolvePromisseOrWait()
    })
  },

  initMap() {
    autocomplete = new google.maps.places.Autocomplete(
      (document.getElementById(searchInput)),
      { types: ['(cities)'], componentRestrictions: {'country': 'br'} }
    )

    autocomplete.addListener('place_changed', function(){
      let place = autocomplete.getPlace()
      let input = $(`#${searchInput}`)
      let text = $(input).val()

      if (text.length > 0){
        // By default the value that google provides looks like:
        // => "Osasco, SP, Brasil"
        //
        // In some cases the value that google provides comes messy, for example:
        // => "Barra do Piraí - Santa Cecília, Barra do Piraí - RJ, Brasil"
        //
        // We need set in input the value like:
        // => "Osasco, SP"
        // => "Barra do Piraí, RJ"

        let city = ''
        let state = ''
        let peaces = text.split(' - ')
        if(peaces.length == 1){
          peaces = text.split(',')
          city = peaces[0]
          state = peaces[1]
          $(input).val(`${city},${state}`)
        } else if(peaces.length > 1){
          city = peaces[0]
          state = peaces[peaces.length -1].split(',')[0]
          $(input).val(`${city}, ${state}`)
        } else {
          $(input).val(place.name)
        }

        submitSearchForm()
      }
    })
  },

  submitSearchForm() {
    $('#search-submit').click()
  },

  clearField(field) {
    $(field).val('')
      .removeAttr('checked')
      .removeAttr('selected')
      .trigger('change')
  },

  toogleFilters(action) {
    if(action == 'hide')
      $('#filters').addClass('d-none')
    if(action == 'show')
      $('#filters').removeClass('d-none')
  },

}

export default component
