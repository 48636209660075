import { Component } from "@page/component"

const component = new Component()

component.expose = {
  dBlock(element) {
    $(element).removeClass("d-none")
  },
  dNone(element) {
    $(element).addClass("d-none")
  },
  enableSubmit(){
    const element = $("#visit_feedback_submit")
    element.removeClass('disabled')
    element.removeClass('btn-secondary')
    element.addClass('btn-primary')
  },
  disableSubmit(){
    const element = $("#visit_feedback_submit")
    element.addClass('disabled')
    element.addClass('btn-secondary')
    element.removeClass('btn-primary')
  }
}

export default component
