import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  applyMasks()
}

component.expose = {
  applyMasks() {
    let phoneBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 0 0000-0000' : '(00) 0000-00009'
    }
    $('.phone-field, .phone_with_ddd, .mask-phone').mask(phoneBehavior, { onKeyPress: function(val, e, field, options) {
        field.mask(phoneBehavior.apply({}, arguments), options)
      }})

    let moneyBehavior = function (val) {
      return '#.##0,00'
    }
    $(".currency, .money, mask-money").mask(moneyBehavior, {reverse: true, onKeyPress: function(val, e, field, options) {
        field.mask(moneyBehavior.apply({}, arguments), options)
      }}).attr('maxlength', 12);

    $('.rg-field, .mask-rg').attr('maxlength', 15)
    $(".cnpj-field, .mask-cnpj").mask("00.000.000/0000-00")
    $('.cpf-field, .mask-cpf').mask('000.000.000-00', {reverse: false})
    // $('.date').mask('00/00/0000');
    // $('.time').mask('00:00:00');
    // $('.date_time').mask('00/00/0000 00:00:00');
    // $('.phone').mask('0000-0000');
    // $('.phone_with_ddd').mask('(00) 0000-0000');
    // $('.phone_us').mask('(000) 000-0000');
    // $('.mixed').mask('AAA 000-S0S');
    // $('.cpf').mask('000.000.000-00', {reverse: true});
    // $('.money').mask('000.000.000.000.000,00', {reverse: true});

    $(".cep-field").mask("99999-999", {
      onComplete: function () {
        var cep = $('.cep-field').val().replace(/[^0-9]/, "")


        // Validação do CEP; caso o CEP não possua 8 números, então cancela
        // a consulta
        if (cep.length != 8) {
          return false
        }

        // A url de pesquisa consiste no endereço do webservice + o cep que
        // o usuário informou + o tipo de retorno desejado (entre "json",
        // "jsonp", "xml", "piped" ou "querty")
        var url = "https://viacep.com.br/ws/" + cep + "/json/"

        $.getJSON(url, function (dadosRetorno) {
          try {
            // Preenche os campos de acordo com o retorno da pesquisa
            $(".street").val(dadosRetorno.logradouro)
            $(".neighbourhood	").val(dadosRetorno.bairro)
            $(".city").val(dadosRetorno.localidade)
            $(".state").val(dadosRetorno.uf)
            $(".number").focus()
          } catch (ex) {
          }
        })
      }
    })
  }
}

export default component
