import { Component } from "@page/component"
import { Step3Handler } from './components/step_3_handler'

const component = new Component()

component.ready = function() {
  require('bootstrap-validator')
  const handler = new Step3Handler()
  window.applyMasks()
}

export default component
