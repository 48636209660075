import { Component } from "@page/component"
import { FormHandler } from './components/form_handler'

const component = new Component()

component.ready = function() {
  window.waitGoogleApi().then(()=>{
    const handler = new FormHandler()
  })
}

component.expose = {
  waitGoogleApi(){
    return new Promise((resolve, reject)=>{
      let counter = 0
      const resolvePromisseOrWait = function() {
        counter += 1
        if( window.google )
          resolve()
        else if (counter <= 20)
          setTimeout(resolvePromisseOrWait, 250)
        else
          reject()
      }
      resolvePromisseOrWait()
    })
  }
}

export default component
