import { Component } from "@page/component"

const component = new Component({
    mixins: ['users/registrations/new.js']
})

component.ready = function() {
    preloadImages()
    verifyRole()
}

export default component
