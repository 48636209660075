import { Component } from "@page/component"
import { PropertiesList } from "./components/properties_list";

const component = new Component()

let autocomplete, list
let searchInput = 'autocomplete'

component.ready = function () {
  list = new PropertiesList(`#${searchInput}`, '#search-button', '#search-result')
}

component.expose = {
  initMap() {
    autocomplete = new google.maps.places.Autocomplete(
      (document.getElementById(searchInput)),
      { types: ['(cities)'], componentRestrictions: {'country': 'br'} }
    )

    $(function () { list.configureGoogleAutocomplete(autocomplete) })
  }
}

export default component
