import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  window.lease_id = $('#lease_id').val()

  // modal event
  $('#details-modal').on('hidden.bs.modal', function (e) {
    Loading.hide()
  })

  // transaction actions
  $(".option-choice").on('change', function() {
    load_events_for(this)
  })

  $('#render-transactions').on('DOMSubtreeModified', function() {
    $(".option-choice").on('change', function() {
      load_events_for(this)
    })
  })

  // filter transactions
  $("#order_choice").on('change', function() {
    filterTransactions()
  })

  filterTransactions()
}

component.start = function() {
  // ajax events
  $(document).ajaxStart(function() {
    Loading.show()
  })
  $(document).ajaxStop(function() {
    Loading.hide()
  })
}

component.expose = {
  load_events_for(e) {
    var selectedOption = $(e).children("option:selected").val()
    var transaction_id = $(e).parent().find('#transaction_id').val()
    $(e)[0].value = ''

    if (selectedOption === 'edit') {
      // redirect to edit page
      window.location.href = url_paths(selectedOption, transaction_id)

    } else {
      // render partial
      $.ajax({
        method: 'GET',
        url: url_paths(selectedOption, transaction_id),
        data: { partial_name: selectedOption }
      })
    }
  },

  url_paths(action, transaction_id) {
    let paths = {
      'order':    `/app/landlords/rents/leases/${window.lease_id}/transactions/ordered_transactions`,
      'edit':     `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/edit`,
      'details':  `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/transaction_actions`,
      'check':    `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/transaction_actions`,
      'cancel':   `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/transaction_actions`,
      'billet':   `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/transaction_actions`,
      'resend':   `/app/landlords/rents/leases/${window.lease_id}/transactions/${transaction_id}/resend_transaction`,
    }
    return paths[action]
  },

  filterTransactions() {
    let order = $("#order_choice").children("option:selected").val()

    $.ajax({
      method: 'GET',
      url: url_paths('order'),
      data: { order }
    })
  },

  activeCancel() {
    $('#cancel-button').removeAttr('disabled')
    $('#button-to-check').addClass('d-none')
    $('#button-to-cancel').removeClass('d-none')
  },

  activeCheck() {
    $('#button-to-check').removeClass('d-none')
    $('#button-to-cancel').addClass('d-none')
  },
}

export default component
