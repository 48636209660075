import { Component } from "@page/component"
import { Loading } from "@lib/animations/loading"

const component = new Component()

component.expose = { Loading }

component.ready = function() {
  $(document).on('keydown', function(e){
    // ESC
    if ( e.keyCode === 27 ) {
      if(Loading.isOpen()){
        Loading.hide()
      }
    }
  })

  $(document).on('click', '.loading-toggle', function(e){
    Loading.toggle()
  })
}

export default component
