import { Component } from "@page/component"

const component = new Component()

component.expose = {
  startCheckout(plan_id){
    // 1. obtém configurações para o checkout
    getCheckoutSettings(plan_id).done(function (settings) {

      // 2. inicia o checkout do Pagarme
      pagarmeCheckout(settings).then(()=> Loading.hide())
    })
  },

  getCheckoutSettings(plan_id) {
    return $.get(`/app/billing/plans/${plan_id}/checkout_settings`)
  },

  pagarmeCheckout(settings) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(window.location.search)
      const checkout = new PagarMeCheckout.Checkout({ encryption_key: settings.encryption_key })

      let checkoutConfig = {
        customerData: 'true',
        createToken: 'false',
        amount: settings.plan.amount,
        headerText: `Plano ${settings.plan.name} - {price_info}`,
        paymentButtonText: 'Pagar',
        buttonText: 'Assinar',
        buttonClass: 'btn btn-primary',
        uiColor: '#60C8D0',
        disableZeroDocumentNumber: 'false',
        paymentMethods: 'credit_card',
        card_brands: 'elo,amex,diners,jcb,hipercard,visa,aura,discover,mastercard',
        defaultInstallment: '1',
        freeInstallments: '0',
        postbackUrl: settings.postback_url,
        customer: {
          external_id: `${settings.user.id}`,
        }
      }

      // configuração extra para boleto
      if (params.get('payment_method') === 'boleto'){
        checkoutConfig = { ...checkoutConfig,
          paymentMethods: 'boleto,credit_card',
          boletoExpirationDate: moment().add(2, 'days').format('DD/MM/YYYY'),
        }
      }

      // 3. abre o modal de checkout
      checkout.open(checkoutConfig)

      // 4. processa a resposta do checkout
      checkout.success = function (response) {
        feedbackModal('in_progress')

        // 5. cria a assinatura
        subscribe(settings, response)
          .done(function (data) {
            if (response.payment_method === 'boleto'){
              window.location.href = '/app/billing/subscriptions/resume'
            } else {
              feedbackModal('success')
              $('#subscription_feedback_modal').modal('toggle')
              $('#subscriber_goal_modal').modal('toggle')
            }
          })
          .fail(function (jqXHR) {
            let error = jqXHR.responseJSON
            feedbackModal('error', error)
          })
      }

      checkout.error = function (error) {
        feedbackModal('error')
        console.log(error)
      }

      checkout.close = function () {
        console.log('The payment modal has been closed.')
      }

      // aguarda (até 6 segundos) a abertura do modal do Pagarme, para então resolver a Promisse
      let counter = 0
      const resolvePromisseOrWait = function() {
        counter += 1
        if( $('#pagarme-checkout-container').css('display') == 'block' )
          resolve()
        else if (counter <= 20)
          setTimeout(resolvePromisseOrWait, 300)
        else
          reject()
      }
      resolvePromisseOrWait()
    })
  },

  subscribe(settings, response) {
    let checkout = { settings, response }
    return $.post(`/app/billing/subscriptions/subscribe`, { checkout })
  },

  feedbackModal(status, error) {
    let modal = $('#subscription_feedback_modal')
    let body_success = modal.find('#feedback_success')
    let body_in_progress = modal.find('#feedback_in_progress')
    let body_error = modal.find('#feedback_error')
    let body_already_has_subscription = modal.find('#feedback_already_has_subscription')

    $(body_success).hide()
    $(body_in_progress).hide()
    $(body_error).hide()
    $(body_already_has_subscription).hide()

    if (error) {
      $(body_error).find('#error').text(error)
      $(body_error).find('#error').show()
    }

    $(eval(`body_${status}`)).show()
    $(modal).modal('show')
  }
}

export default component
