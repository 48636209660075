import { Component } from "@page/component"
import * as ClipboardJS from "clipboard"

const component = new Component()
const urlParams = new URLSearchParams(window.location.search)

component.ready = function(){
  initClipboard()
  $('.js-link').on('click', function(){
    redirectTo($(this).data('href'), $(this).data('target'))
  })

  $('.enable-edit').on('click', function(){
    $('.edit-field').removeAttr("readonly")
    $(this).addClass('d-none')
  })

  checkPopUpModal()
  
}

component.expose = {
  equalizeHeight(elements) {
    let sizes = $(elements).map(function() { return $(this).height()}).toArray()
    let bigger = sizes.sort(function(a, b) { return a - b }).slice(-1)[0]
    $(elements).each(function(){ $(this).height(bigger) })
  },

  equalizeWidth(elements) {
    let sizes = $(elements).map(function() { return $(this).width()}).toArray()
    let bigger = sizes.sort(function(a, b) { return a - b }).slice(-1)[0]
    $(elements).each(function(){ $(this).width(bigger) })
  },

  redirectTo(path, target) {
    switch (target) {
      case '_blank':
        window.open(path, '_blank');
        break;
      default:
        window.location.href = path
    }
  },

  initClipboard() {
    const clip = new ClipboardJS('.jscopy');
    clip.on("success", function () {
      iziToast.success({
        title: 'Sucesso!',
        message: 'Copiado para área de transferência.',
        position: 'topRight'
      })
    })
  },

  getAJAXRequests: (function() {
    let oldSend = XMLHttpRequest.prototype.send,
      currentRequests = [];

    XMLHttpRequest.prototype.send = function() {
      currentRequests.push(this); // add this request to the stack
      oldSend.apply(this, arguments); // run the original function

      // add an event listener to remove the object from the array
      // when the request is complete
      this.addEventListener('readystatechange', function() {
        let idx;

        if (this.readyState === XMLHttpRequest.DONE) {
          idx = currentRequests.indexOf(this);
          if (idx > -1) {
            currentRequests.splice(idx, 1);
          }
        }
      }, false);
    };

    return function() {
      return currentRequests;
    }
  }()),

  isMobile(){
    return (window.matchMedia("(max-width: 768px)").matches)
  },

  scrollItOnCenter(element){
    let $window = $(window),
      $element = $(element),
      elementTop = $element.offset().top,
      elementHeight = $element.height(),
      viewportHeight = $window.height(),
      scrollIt = elementTop - ((viewportHeight - elementHeight) / 2);
    $window.scrollTop(scrollIt);
  },

  scrollItOnBottom(element){
    let $window = $(window),
      $element = $(element),
      elementTop = $element.offset().top,
      viewportHeight = $window.height(),
      scrollIt = elementTop - ((viewportHeight - (viewportHeight / 2)));
    $window.scrollTop(scrollIt);
  },

  mergeFieldWithErrorsForCustomCheckbox(field) {
    var fields = $(`${field} .field_with_errors`);
    $(fields).each(function(){
        this.outerHTML = this.innerHTML
    })
  },

  checkPopUpModal() {
    let _modal = urlParams.get('modal')
    if(_modal){
        $(`#${_modal}`).modal('toggle')
    }
  },

  disableFields(e) {
    for(let item of e) {
      item.setAttribute('disabled', true)
    }
  },

  enableFields(e) {
    for(let item of e) {
      item.removeAttribute('disabled')
    }
  },

  changeFieldDisplay(field) {
    field.toggle()
  }
}

export default component
