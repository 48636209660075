import { Component } from "@page/component"

const component = new Component()

component.expose = {
  enableSubmit(){
    $('#rental_management_submit').removeClass('disabled')
    $('#rental_management_submit').removeClass('btn-secondary')
    $('#rental_management_submit').addClass('btn-primary')
  },
  disableSubmit(){
    $('#rental_management_submit').addClass('disabled')
    $('#rental_management_submit').addClass('btn-secondary')
    $('#rental_management_submit').removeClass('btn-primary')
  }
}

component.ready = function() {
  $('#rental_management').on('change', function(e){
    if ($(this).is(":checked"))
      window.enableSubmit()
    else
      window.disableSubmit()
  })
}

export default component
