import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
// step 2
  initCurrency()

  initChecks($('#rent_lease_bonus_for_payment_on_time'), $('#addCost'))
  initChecks($('#rent_lease_iptu_price'), $('#iptu'))
  initChecks($('#rent_lease_additional_costs'), $('#additional'))

  initFields($('#rent_lease_bonus_for_payment_on_time'), $('.extra-fields') )
  initFields($('#rent_lease_iptu_price'), $('.iptu-fields') )
  initFields($('#rent_lease_additional_costs'), $('.additional-fields') )


  $('#iptu').on('change', function(){
    cleanFields($('.iptu-field'))
    changeFieldDisplay($('.iptu-fields'))
    changeFieldRequire($('.iptu-field'), this.checked)

    if(this.checked){
      setSelectBoxValue($('#rent_lease_iptu_start_month')[0], 'January')
      setSelectBoxValue($('#rent_lease_iptu_end_month')[0], 'December')
    }

    sumFields()
  })
  $('#additional').on('change', function(){
    cleanFields($('.additional-field'))
    changeFieldDisplay($('.additional-fields'))
    sumFields()
  })
  $('#addCost').on('change', function(){
    cleanFields($('.extra-payment'))
    changeFieldDisplay($('.extra-fields'))
    sumFields()
  })

  $('#penalty').on('change', function(){
    changeFieldStatus($('#rent_lease_penalty_percentage'), !this.checked)
  })
  $('#interest').on('change', function(){
    changeFieldStatus($('#rent_lease_interest_percentage'), !this.checked)
  })

  // initFields(parent, fields, displayField)
}

component.expose = {

  initCurrency() {
    $('.currency').mask('#.##0,00', {reverse: true})

    if ($("#hdnTotal").length) {
      sumFields()

      $(".total").on("change", function () {
        sumFields()
      })
    }
  },

  cleanFields(fields) {
    fields.map(function(){ this.value = '' })
  },

  initChecks(field, box) {
    box[0].checked = (field.val() != '')
  },

  initFields(parent, displayField) {
    if(parent.val() == '')
      changeFieldDisplay(displayField)
  },

  changeFieldStatus(fields, value) {
    fields.map(function(){ this.disabled = value })
  },

  changeFieldRequire(fields, value) {
    fields.map(function(){ this.required = value })
  },

  setSelectBoxValue(box, value) {
    box.value = value
  },

  changeFieldDisplay(field) {
    field.toggle('d-none')
  },

  sumFields() {
    var sum = 0

    $(".total").each(function () {
      var strValue = $(this).val().trim()
      if (strValue == '') return true

      var floatValue = parseFloat(strValue.replace(/\./g, '').replace(/,/g, '.'))
      sum += floatValue
    })

    $("#hdnTotal").val($(".currency").masked(sum.toFixed(2)))
    $("#spanTotal").text($("#hdnTotal").val());
  },

}

export default component
