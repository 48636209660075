import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // url append active tab name
  $('.nav-items > a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    let tab = $(e.target).data('tab-url')
    if(tab){
      let path = window.location.href.split('/').slice(0, -1)
      path.push(tab)
      window.history.pushState({}, null, path.join('/'));
    }
  })

  // open/close dropdown
  $('.nav-dropdown > .nav-toggler').on('click', function(e) {
    let container = $(this).data('target')
    $(container).toggleClass($(this).data('toggle-class'))
  })
  $('.nav-dropdown > .nav-items > .nav-link').on('click', function(e) {
    let toggler = $(e.target).closest('.nav-dropdown').find('.nav-toggler')
    $(toggler).find('.option').text($(e.target).text())
    $(toggler).find('.option').addClass('active')
    $(toggler).click()
  })
}

export default component
