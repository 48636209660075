import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // step 4
  toggleTenantFields()

  $('.only_landlord_email').on('change', function(){
    if(this.checked){
      disableEmailInq()
    } else {
      enableEmailInq()
    }
  })

  $('.person-type-tenant').on('change', function(){
    if(this.value === 'individual'){
      changeTenantFieldStatus($('.cnpj-fields-tenant'), true)
      changeTenantFieldStatus($('.cpf-fields-tenant'), false)
    } else if(this.value === 'company') {
      changeTenantFieldStatus($('.cpf-fields-tenant'), true)
      changeTenantFieldStatus($('.cnpj-fields-tenant'), false)
    }
  })
}

component.expose = {

  toggleTenantFields() {
    let add_button = $("#add_tenant")
    let input_name = $('#rent_lease_tenants_attributes_0_full_name')
    if(!input_name.length)
      add_button.click()
    $(add_button).hide()
  },

  disableEmailInq() {
    document.getElementsByClassName('email-inq-field')[0].classList.add('d-none')
    document.getElementsByClassName('email-inq-input')[0].setAttribute('disabled', 'true')
  },

  enableEmailInq() {
    document.getElementsByClassName('email-inq-field')[0].classList.remove('d-none')
    document.getElementsByClassName('email-inq-input')[0].removeAttribute('disabled')
  },

  changeTenantFieldStatus(fields, disable) {
    fields.each(function(){
      $(this).attr('disabled', disable)
      $(this).toggle('d-none').removeClass('d-none')
    })
  },

}

export default component
