export const FormValidator = {

  validatePresence (field) {
    if (!field.val()) {
      this.fieldError(field)
      return false
    } else {
      this.clearFieldError(field)
      return true
    }
  },

  validateAtLeastOneChecked(checkboxes) {
    if (!checkboxes.map(function() { return $(this).is(":checked") }).toArray().includes(true)){
      this.checkboxesError(checkboxes)
      return false
    } else {
      this.clearCheckboxesError(checkboxes)
      return true
    }
  },

  validateBetween (field, min, max){
    if(field.val() < min || field.val() > max){
      field.addClass('is-invalid')
      field.parent('.form-group').append(`<ul class="list-unstyled text-invalid"><li>Coloque um valor de ${min} a ${max}</li></ul>`)
      return false
    } else {
      this.clearFieldError(field)
      return true
    }
  },

  fieldError (field) {
    if(! field.hasClass('is-invalid')) {
      field.addClass('is-invalid')
      field.parent('.form-group').append('<ul class="list-unstyled text-invalid"><li>Não pode ficar em branco</li></ul>')
    }
  },

  checkboxesError(checkboxes) {
    if(! $(checkboxes[0]).hasClass('is-invalid')) {

      for(let checkbox of checkboxes){
        $(checkbox).addClass('is-invalid')
      }

      $(checkboxes[0]).parents('.form-group').append(
        '<ul class="list-unstyled text-invalid"><li>Selecione uma opção</li></ul>'
      )
    }
  },

  clearCheckboxesError(checkboxes) {
    for(let checkbox of checkboxes){
      $(checkbox).removeClass('is-invalid')
    }
    $(checkboxes[0]).parents('.form-group').find('ul.text-invalid').remove()
  },

  clearFieldError(field) {
    field.removeClass('is-invalid')
    field.parent('.form-group').find('ul.text-invalid').remove()
  }

}
