import { Component } from "@page/component"

const component = new Component({
  mixins: ['app/public/visit_feedbacks/_form.js']
})

component.ready = function() {

  // watch reason_not_to_rent radio buttons
  $('input[name="visit_feedback[reason_not_to_rent]"]').on('change', function() {
    const input = $("input[name='visit_feedback[another_reason_not_to_rent]']")

    if ($(this).is(":checked") && $(this).val() === "other"){
      window.dBlock("#another_reason")
      input.attr('required', 'true')
    }
    else {
      window.dNone("#another_reason")
      input.removeAttr('required')
      input.val("")
    }
  })

}

export default component
