import { Component } from "@page/component"

const component = new Component({
  mixins: [
    'app/landlords/properties/listings/_step_3.js',
    'app/landlords/properties/listings/_step_5.js',
  ]
})

export default component
