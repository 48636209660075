import { Component } from "@page/component"
import iziToast from "iziToast"

const component = new Component({
  mixins: [
    'app/billing/commercial_orders/_modals.js',
    'app/landlords/properties/tabs/_listing.js'
  ]
})

component.ready = function () {
  // equalize elements height
  equalizeHeight($('.description-wrapper'))
  equalizeHeight($('.address-wrapper'))
  equalizeHeight($('.status-wrapper'))

  // flip card
  $('.btn-flip').on('click', function (e) {
    e.preventDefault()
    $(e.target).closest('.card-flip').toggleClass('flipped')
  })

  // premium switch
  $('.premium-switch').on('click', function (e) {
    e.preventDefault();
    let _switch = $(e.target)
    let subscriber = _switch.data('subscriber')
    let authorized = _switch.data('authorized')
    let properties = _switch.data('number-of-properties')

    if (_switch.data('checked')) {
      if (!subscriber)
        fireSubscriptionModal()
      else if (!authorized)
        fireExceededPremiumModal(properties)
      else {
        // activate
        Loading.show()
        $.ajax({ method: 'POST', url: _switch.data('url') })
          .done(function (response) { window.location.href = `/app/landlords/properties` })
          .fail(function (jqXHR) {
            if (jqXHR.status === 403) {
              fireExceededPremiumModal()
              Loading.hide()
            }
          })
      }
    } else {
      // deactivate
      let modal = $('#deactivate-premium-modal')
      modal.find('form').attr('action', _switch.data('url'))
      modal.find('#name').text(_switch.data('name'))
      if (_switch.data('has-listing-active'))
        modal.find('#report-listing').removeClass('d-none')
      if (_switch.data('has-lease-active'))
        modal.find('#report-lease').removeClass('d-none')
      modal.modal('toggle')
    }
  })

  // new property link
  $('#new-property-link').on('click', function (e) {
    e.preventDefault()
    let link = $(e.target)
    if (link.data('free-properties-exceeded') === true) {
      $('#exceeded-free-feedback-modal').modal('toggle')
    } else {
      window.location.href = link.attr('href')
    }
  })

}

export default component
