import { Component } from "@page/component"

const component = new Component({
  mixins: [
    'app/landlords/properties/tabs/_listing.js',
    'app/landlords/properties/tabs/_contracts.js',
    'app/landlords/properties/tabs/_leases.js'
  ]
})

export default component
