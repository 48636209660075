import { Component } from "@page/component"
import Bugsnag from '@bugsnag/js'

const meta = document.getElementsByName('bugsnag')[0]

try{
  Bugsnag.start({
    apiKey: meta.getAttribute('api_key'),
    autoDetectErrors: false,
  })
} catch(err){
  console.info(err.message)
}

export default new Component({
  expose: { Bugsnag }
})
