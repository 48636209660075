import { Component } from "@page/component"

const component = new Component({
    mixins: ['users/shared/helper.js']
})

component.ready = function() {
    if(checkSalesFlow()){
        $('#dynamic-text').html('Venda rápido, sem stress.')
        $('#sign-up-link').attr('href', `${$('#sign-up-link').attr('href')}?sales=true`)
    }
}

export default component