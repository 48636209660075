import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // step 5
  searchFileName()
  fileNameOnInput()
}

component.expose = {
  fileNameOnInput() {
    $(document).on('change', '.input-upload', function(e) {
      let input = $(e.target)

      if ($(input)[0].files.length > 0) {
        let file = $(input)[0].files[0].name
        $(input).prev('label').text(file)
      }
    })
  },

  searchFileName() {
    $.each($('.input-upload'), function() {
      let input = $(this)
      let idDoc = $(input).data('id')
      let url = $(input).data('url') + `/${idDoc}.json`

      if (idDoc) {
        $.ajax({ method: 'GET', url: url, dataType: "json" }).done(function(response) {
          let urlArray = response.url.split('/')
          let fileName = urlArray[urlArray.length - 1]
          $(input).prev('label').text(fileName)
        })
      }
    })
  },
}

export default component
