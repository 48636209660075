export class Loading {
  static element(){
    return $(".loading")
  }

  static show(){
    Loading.element().show()
  }

  static hide(){
    Loading.element().hide()
  }

  static isOpen(){
    return !(Loading.element().css('display') == 'none')
  }

  static isClose(){
    return (Loading.element().css('display') == 'none')
  }

  static toggle(){
    if(Loading.isOpen()){
      Loading.hide()
    } else{
      Loading.show()
    }
  }
}
