// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../application/images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
Rails.start()
ActiveStorage.start()
import "channels"
import "controllers"

// plugins
require('jquery-mask-plugin')
require("@stisla/node_modules/popper.js")
require("@stisla/node_modules/bootstrap/dist/js/bootstrap")
require('@stisla/assets/js/stisla')
require('@stisla/assets/js/scripts')
require('@stisla/node_modules/select2/dist/js/select2.full')
require('multi-select/jquery.multi-select-esm')
// import TogetherButton from 'together-button-web'
// const togtherButton = new TogetherButton()

// plugins that are included in app and app_sales but not in app_public
// import { RailsHelper } from '../helpers/rails'
if (['app', 'app_sales'].includes(RailsHelper.currentLayout())) {
  require('@stisla/node_modules/selectric/public/jquery.selectric')
  require('@stisla/node_modules/jquery_upload_preview/assets/js/jquery.uploadPreview.min')
  require('@stisla/node_modules/jquery.nicescroll/dist/jquery.nicescroll')
}

// expose
require('expose-loader?flatpickr!flatpickr')
require('expose-loader?moment!moment')
require('expose-loader?iziToast!iziToast')
require('expose-loader?swal!swal')

// cocoon
require('cocoon/app/assets/javascripts/cocoon')

// app
require("@javascripts/app")

// only on devise path
if (RailsHelper.currentPath().match(/^users\//)) {
  require("@javascripts/users")
  require('bootstrap-show-password/bootstrap-show-password')

  document.addEventListener("DOMContentLoaded", function () {
    $('input.password').on('focus', function() {
      $(this).siblings('.input-group-append').addClass('password-in-focus')
    })
    $('input.password').on('focusout', function() {
      $(this).siblings('.input-group-append').removeClass('password-in-focus')
    })
  })
}