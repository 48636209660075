import { Component } from "@page/component"

const component = new Component({
    mixins: [
        'app/landlords/lease_approaches/_first_lease_approach_tour.js',
        'app/landlords/lease_approaches/_first_user_application_tour.js'
      ]
    }
)
const urlParams = new URLSearchParams(window.location.search)
var word = null;

component.ready = function() {
    $(window).on('load', function(){
        setListingByUrlParam()
        filterInteresteds()
    })

    $(document).on('DOMSubtreeModified', function(){
        $('.documents-request').on('click', function(){
            addUrlToForm($(this))
        })
        $('.fill-action').on('click', function(){
            addLinkTo($(this))
        })
    })

    $('.contact-page-tab').on('click', function(e){
        changeContactTab(e)
    })

    $('.filter_by_listing').on('change', function(){
        filterInteresteds()
    })

    $('.filter_interesteds').on('change', function(){
        filterInteresteds()
    })

    $('.only_verified').on('change', function(){
        filterInteresteds()
    })

    $('.filter_by_word').on('keyup', function(){
        if(word != this.value){
            word = this.value
            filterInteresteds()
        }
    })

    $("#headingFilter").on('click', function(){
        $(".rotate-icon").toggleClass("down"); 
    });
}

component.expose = {
    addLinkTo(e) {
        let _target = e.data('link')

        $(`#${_target}`).attr('href', e.data('href'))
        $(`#${_target}`).attr('data-method', e.data('method'))
    },

    addUrlToForm(e) {
        let _target = e.data('form')

        $(`#${_target}`).attr('action', e.data('url'))
    },

    changeContactTab(e) {
        $('.contact-page-tab').each(function(){
            $(this).removeClass('registered-tab-active')
            $(this).removeClass('contact-tab-active')
        })

        $(e.currentTarget).addClass($(e.currentTarget).data('active-class'))
    },

    filterInteresteds() {
        let filter = $(".filter_interesteds").children("option:selected").val()
        let filter_listing = $("#filter_by_listing_interesteds").children("option:selected").val()
        let filter_verifier = $(".only_verified").is(':checked')
        let filter_word = $(".filter_by_word").val()

        return $.ajax({
            method: 'GET',
            url: `/app/landlords/lease_approaches/search_interesteds`,
            data: { 
                filter_lease_approaches: filter,
                filter_lease_approaches_by_listing: filter_listing,
                filter_lease_approaches_verified: filter_verifier,
                filter_lease_approaches_by_word: filter_word
            }
        })
    },

    setListingByUrlParam() {
        let listing_id = urlParams.get('listing_id')

        if(listing_id != undefined){
            $("#filter_by_listing_interesteds").val(listing_id)
        }
    }
}

export default component
