import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
    mergeFieldWithErrorsForCustomCheckbox('.custom-checkbox')

    $("#rental_guarantees_deposit").on('change', function(){
        changeFieldDisplay($('.depositQuantity'))
        if(this.checked){
            enableFields($('#user_user_preference_attributes_deposit_quantity'))
        }else{
            disableFields($('#user_user_preference_attributes_deposit_quantity'))
        }
    })

}

export default component
