import { Component } from "@page/component"

const component = new Component()

component.ready = function(){
  $('.create-commercial-order-action').on('click', function(e){
    e.preventDefault()

    let $modal = $(`${$(this).data('commercial-order-modal')}`),
      property_id = $(this).data('commercial-order-property-id')
    $modal.find('input[name="billing_commercial_order[property_id]"]').val(property_id)
    $modal.modal('toggle')
  })
}

export default component
