const meta = document.getElementsByName('rails_helper')[0]

export const RailsHelper = {
  currentLayout() {
    return meta.getAttribute('layout')
  },
  currentModule() {
    return `${meta.getAttribute('path')}/${meta.getAttribute('action')}`
  },
  currentPath() {
    return meta.getAttribute('path')
  },
  currentAction() {
    return meta.getAttribute('action')
  }
}
