import { Component } from "@page/component"

const component = new Component({
  mixins: [ 'app/billing/commercial_orders/_modals.js' ]
})

component.ready = function() {

  $('#e-signature-request').on('click', function(){
    if($(this).data('subscriber') == true) {
      $('#e-signature-container').addClass('d-none')
      $('#e-signature-form').removeClass('d-none')
    }
  })

  $(".input-upload").click(function(){
    $('#file-input').trigger('click')
  })

  $("#file-input").change(function () {
    if (this.files && this.files[0]) {
      let input_filename = $('#input-filename')
      input_filename.find('div').text(`Upload de ${this.files[0].name} realizado com sucesso!`)
      input_filename.removeClass('d-none')
      $('button.input-upload').addClass('d-none')

      enableSubmit()
    } else {
      // disableSubmit()
    }
  })
}

component.expose = {
  enableSubmit() {
    let submit_button = $('#e-signature-submit')
    submit_button.attr('type', 'submit')
    submit_button.removeClass('btn-secondary')
    submit_button.addClass('btn-primary')
  },
  disableSubmit() {
    let submit_button = $('#e-signature-submit')
    submit_button.attr('type', 'button')
    submit_button.removeClass('btn-primary')
    submit_button.addClass('btn-secondary')
  }
}

export default component
