import { Component } from "@page/component"
import { DropzoneHandler } from  "./components/dropzone_handler";

const component = new Component()

component.ready = function() {
  const handler = new DropzoneHandler()

  $('#listing-submit-5').on('click', function(event) {
    event.preventDefault()

    if(handler.validate()){
      let $form = $('form#listing_form')
      let $button = $(this)
      $button.attr('disabled', 'true')

      window.Loading.show()
      handler.commitImages()
        .done((response) => $form.submit())
        .fail((error) => console.log(error))
    }
  })
}

export default component
