import { Component } from "@page/component";

const component = new Component()

component.ready = function () {
  $('.check-reason').on('change', function(){
    let checkboxes = $('.check-reason')
    let has_any_checkbox_checked = checkboxes.map(function() { return $(this).is(":checked") }).toArray().includes(true)

    if (has_any_checkbox_checked){
      enableUnsubscribeReasonSubmit()
    } else{
      disableUnsubscribeReasonSubmit()
    }
  })

  $('.text-reason').on('keyup keydown change', function(){
    if($(this).val() != '') {
      enableUnsubscribeReasonSubmit()
    } else {
      disableUnsubscribeReasonSubmit()
    }
  })
}

component.expose = {
  enableUnsubscribeReasonSubmit(){
    let submit = $('#submit_unsubscribe_reason_form')
    submit.removeClass('disabled')
    submit.attr('type', 'submit')
  },
  disableUnsubscribeReasonSubmit(){
    let submit = $('#submit_unsubscribe_reason_form')
    submit.addClass('disabled')
    submit.attr('type', 'button')
  }
}

export default component
