import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  check_select_guarantee()
  $('#guarantee_select').on('change', function(){
    check_select_guarantee()
  })

  check_box_married()
  $('#married').on('change', function(){
    check_box_married()
  })
}

component.expose = {

  check_select_guarantee() {
    let select = $('#guarantee_select')[0]
    if(select === undefined)
      return true

    if(select.value === 'guarantor'){
      $('#deposit-info').addClass('d-none')
      $('#insurance-info').addClass('d-none')
      $('#guarantor-info').removeClass('d-none')

      enableFields($('.guarantor'))
      enableFields($('#married'))
      disableFields($('.deposit'))
      disableFields($('.insurance'))

      check_box_married();
    } else if(select.value === 'deposit'){
      $('#guarantor-info').addClass('d-none')
      $('#insurance-info').addClass('d-none')
      $('#deposit-info').removeClass('d-none')

      enableFields($('.deposit'))
      disableFields($('.guarantor'))
      disableFields($('.married'))
      disableFields($('#married'))
      disableFields($('.insurance'))
    } else if(select.value === 'bond_insurance'){
      $('#guarantor-info').addClass('d-none')
      $('#deposit-info').addClass('d-none')
      $('#insurance-info').removeClass('d-none')

      enableFields($('.insurance'))
      disableFields($('.guarantor'))
      disableFields($('.married'))
      disableFields($('#married'))
      disableFields($('.deposit'))
    } else {
      $('#guarantor-info').addClass('d-none')
      $('#deposit-info').addClass('d-none')
      $('#insurance-info').addClass('d-none')

      disableFields($('.deposit'))
      disableFields($('.guarantor'))
      disableFields($('.married'))
      disableFields($('#married'))
      disableFields($('.insurance'))
    }
  },

  check_box_married() {
    let check = $('#married')[0]
    if(check === undefined)
      return true

    if(check.checked){
      $('#spouse-info').removeClass('d-none')
      $('#spouse-info').fadeIn()

      enableFields($('.married'))
    } else {
      $('#spouse-info').fadeOut()
      disableFields($('.married'))
      enableFields($('#married'))
    }
  }

}

export default component
