import consumer from "./consumer"
import iziToast from "iziToast"

consumer.subscriptions.create("UserNotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    switch (data.status) {
      case 'notice':
        iziToast.success({
          title: 'Sucesso!',
          message: data.message,
          position: 'topRight'
        })
        break
      case 'error':
      case 'alert':
        iziToast.error({
          title: 'Falha',
          message: data.message,
          position: 'topRight'
        })
        break
    }
  }
});
