import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  initializeCurrency()
  applyMasks()

  initField($('.condo-fee'), $('#listing_sale_condo_fee'), $('#condo-fee-check'))
  initField($('.iptu'), $('#listing_sale_iptu_price'), $('#iptu-check'))

  $('#condo-fee-check').on('change', function(){
    changeFieldStatus($('.condo-fee'), $('#listing_sale_condo_fee'), $('.condo-fee-check-input'), true, this.checked)
  })

  $('#iptu-check').on('change', function(){
    changeFieldStatus($('.iptu'), $('#listing_sale_iptu_price'), $('.iptu-check-input'), true, this.checked)
  })

  $('#condo-fee-check-mobile').on('change', function(){
    changeFieldStatus($('.condo-fee'), $('#listing_sale_condo_fee'), $('.condo-fee-check-input'), true, this.checked)
  })

  $('#iptu-check-mobile').on('change', function(){
    changeFieldStatus($('.iptu'), $('#listing_sale_iptu_price'), $('.iptu-check-input'), true, this.checked)
  })
}

component.expose = {

  initializeCurrency() {
    if($("#hdnTotal").length) {
      $(".total").on("change", function() {
        sumCurrencyFields()
      })
      sumCurrencyFields()
    }
  },

  sumCurrencyFields() {
    var sum = 0
    $(".total").each(function () {
      var strValue = $(this).val().trim()
      if(strValue == '')
        return true
      let floatValue = parseFloat(strValue.replace(/\./g, '').replace(/,/g, '.'))
      sum += floatValue
    })
    $("#hdnTotal").val($(".currency").masked(sum.toFixed(2)))
    $("#spanTotal").text($("#hdnTotal").val());
  },

  changeFieldStatus(div, field, checkboxes, changeValue, check) {
    if(checkboxes != undefined){
      checkboxes.attr('checked', check)
    }

    div.toggle('d-none')
    if(changeValue){
      field.val('')
    }

    if(field.attr('required')){
      field.removeAttr('required')
    }else{
      field.attr('required', true)
    }
  },

  initField(div, field, check){
    if(check[0].checked){
      changeFieldStatus(div, field, undefined, false, undefined)
    }
  }

}

export default component
