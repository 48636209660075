import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // step 3
  let full_name = $("#rent_lease_landlord_bank_account_attributes_full_name")
  let bank_id = $("#rent_lease_landlord_bank_account_attributes_bank_id")
  let agency = $("#rent_lease_landlord_bank_account_attributes_agency")
  let account_number = $("#rent_lease_landlord_bank_account_attributes_account_number")

  $('.person_types').on('change', function(){
    setPersonType($(this).val(), '')
  })

  let select = $("#rent_lease_landlord_bank_account_id")
  $(select).on('change', function () {
    handleFillBankAccount(full_name, bank_id, agency, account_number)
  })
  onLoadFillBankAccount(full_name, bank_id, agency, account_number)
}

component.expose = {

  handleFillBankAccount(full_name, bank_id, agency, account_number) {
    let select = $("#rent_lease_landlord_bank_account_id")

    if (select) {
      let idBank = $(select).val()

      if (idBank) {
        searchBankAccount(idBank).done ( function(response) {
          full_name.val(response.full_name).attr("disabled", true)
          setPersonType(response.person_type, response)
          changePersonTypeField('disable')
          bank_id.val(response.bank_id).attr("disabled", true)
          agency.val(response.agency).attr("disabled", true)
          account_number.val(response.account_number).attr("disabled", true)
        } )
      }
      else {
        full_name.val('').removeAttr('disabled')
        changePersonTypeField('enable')
        resetValField($('.person_type'))
        bank_id.val('').removeAttr('disabled')
        agency.val('').removeAttr('disabled')
        account_number.val('').removeAttr('disabled')
      }
    }
  },

  onLoadFillBankAccount(full_name, bank_id, agency, account_number) {
    let select = $("#rent_lease_landlord_bank_account_id")

    if (select) {
      let idBank = $(select).val()

      if (idBank) {
        searchBankAccount(idBank).done ( function(response) {
          full_name.val(response.full_name).attr("disabled", true)
          setPersonType(response.person_type, response)
          changePersonTypeField('disable')
          bank_id.val(response.bank_id).attr("disabled", true)
          agency.val(response.agency).attr("disabled", true)
          account_number.val(response.account_number).attr("disabled", true)
        } )
      } else {
        if (window.location.href.includes('edit_step')) {
          full_name.val('').removeAttr('disabled')
          changePersonTypeField('enable')
          resetField($('.person_types'))
          resetValField($('.person_type'))
          bank_id.val('').removeAttr('disabled')
          agency.val('').removeAttr('disabled')
          account_number.val('').removeAttr('disabled')
        }
      }
    }
  },

  searchBankAccount(idBank) {
    let url = $("#rent_lease_landlord_bank_account_id").data("url")
    url += `/${idBank}.json`
    return $.ajax({ method: 'GET', url: url, dataType: "json" });
  },

  setPersonType(type, value) {
    if(type === 'individual'){
      $($('.person_individual')[0]).prop('checked', true)
      $('.cpf-fields').removeClass('d-none')
      $('.cnpj-fields').addClass('d-none')
      $("#rent_lease_landlord_bank_account_attributes_cpf").val(value.cpf)
    }else{
      $($('.person_company')[0]).prop('checked', true)
      $('.cnpj-fields').removeClass('d-none')
      $('.cpf-fields').addClass('d-none')
      $("#rent_lease_landlord_bank_account_attributes_cnpj").val(value.cnpj)
    }
  },

  changePersonTypeField(action) {
    if(action === 'disable'){
      $('.person-field').each(function(){
        $(this).attr('disabled', true)
      })
    } else {
      $('.person-field').each(function(){
        $(this).removeAttr('disabled')
      })
    }
  },

  resetField(fields) {
    fields.each(function(){
      $(this).prop('checked', false)
    })
  },

  resetValField(fields) {
    fields.each(function(){
      $(this).val('')
    })
  }
}

export default component
