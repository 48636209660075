import { Component } from "@page/component"

const component = new Component({
    mixins: [
        'app/landlords/lease_approaches/shared/_helper.js'
    ]
})

component.ready = function () {
    $('.changeBox').on('click', function(e){
        e.preventDefault()
        changeBoxView(this)
    })

    $('.display-info').on('click', function(){
        displayInformation(this)
    })
    
    $(document).on('click', function(e) {
        closeBox(e)
    })
}

export default component
