import { Component } from "@page/component"

const component = new Component({
  mixins: ['users/shared/helper.js']
})

const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const regexPhone = /\(\d{2,}\) \d{0,1}\s?\d{4}\-\d{4}/
const validator = array => array.every(value => value === true)

component.ready = function() {
  preloadImages([
    '/img/background/renter-1.webp',
    '/img/background/renter-2.webp',
    '/img/background/renter-3.webp',
    '/img/background/landlord-1.webp'
  ])

  $('body').toggleClass('background')

  checkErrors()
  verifyRole()

  if(checkSalesFlow()){
    $('#dynamic-text').html('Venda rápido, sem stress.')
    $('#sign-in-link').attr('href', `${$('#sign-in-link').attr('href')}?sales=true`)
  }

  $('.btn-flip').on('click', function(event){
    let fieldsToValidate = [
      validateField($('#user_email'), 'Email inválido', regexEmail, event),
      validateField($('#user_full_name'), 'Nome inválido', undefined, event)
    ]

    if(validator(fieldsToValidate)){
      flipCard()
    }
  })

  $('.btn-submit').on('click', function(event){
    let fieldsToValidate = [
      validateField($('#user_phone'), 'Telefone inválido', regexPhone, event)
    ]

    if(!validator(fieldsToValidate)){
      event.preventDefault()
    }
  })

  $('#landlord-select').on('click', function(){
    $('body').removeClass().addClass('background');
    $('#set-role').val('landlord')
    $('#set-success-fee').val(true)
    $('#dynamic-text').fadeOut(500, function(){
      if(checkSalesFlow()){
        $(this).html('Venda rápido, sem stress.').fadeIn(500)
      }else{
        $(this).html('Alugue rápido, para bons inquilinos, sem stress.').fadeIn(500)
      }
    })
  })

  $('#renter-select').on('click', function(){
    changeBackground()
    $('#set-role').val('renter')
    $('#set-success-fee').val(false)
    $('#dynamic-text').fadeOut(500, function(){
      $(this).html('Seu lar está aqui.').fadeIn(500)
    })
  })

  $(window).keydown(function(event){
    if(event.keyCode == 13) {
      event.preventDefault()
      if(!$('.card-flip').hasClass('flipped')){
        $('#flip').click()
      }else{
        if($('#terms-check')[0].checked){
          $('.btn-submit').click()
        }
      }
    }
  })

  $('#terms-check').on('change', function(){
    if(this.checked){
      $('.btn-submit').removeClass('disabled')
    }else{
      $('.btn-submit').addClass('disabled')
    }
  })
}

component.expose = {
  changeBackground() {
    let backgroundImage = Math.round((Math.random() * 2) + 1)
    $('body').addClass(`renter-${backgroundImage}`)
  },

  preloadImages(arrayOfImages) {
    $(arrayOfImages).each(function(){
        $('<img/>')[0].src = this
    })
  },

  verifyRole() {
    if($('#set-role').val() == 'renter'){
      changeBackground()
    }
  },

  flipCard(){
    $('.card-flip').toggleClass('flipped')
    $('.hidden-front').toggleClass('d-none')
  },

  checkErrors() {
    let elements = new Array
    $('.field_with_errors').children().each(function(){
      elements.push(this.name)
    })

    elements = elements.filter(function( element ) {
      return element !== undefined;
    })

    if(elements.length > 0){
      if(!elements.includes('user[email]') && !elements.includes('user[full_name]')){
        flipCard()
      }
    }
  },

  validateField(field, message, regex, event) {
    let fieldValue =  field.val()

    if(fieldValue === '') {
      createError(field, message, event)
    }else{
      if(regex != undefined){
        if(regex.test(fieldValue)){
          field.removeClass('is-invalid')
          field.next("p").remove()
          return true
        }else{
          createError(field, message, event)
        }
      }else{
        field.removeClass('is-invalid')
        field.next("p").remove()
        return true
      }
    }
  },

  createError(element, message, event){
    event.preventDefault()

    if(!element.hasClass('is-invalid')){
      let errorElement = document.createElement('p')
      errorElement.innerHTML = message
      $(errorElement).addClass('text-danger')
      element[0].after(errorElement)
      element.addClass('is-invalid')
    }

    return false
  }
}

export default component
