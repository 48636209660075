import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  if($('.remove-nested').length === 1){
    $('.remove-nested').hide()
  }

  initVisitForm()
}

component.expose = {
  initVisitForm() {
    $('.links').on('cocoon:after-insert', function() {
      flatpickrDateNil()
      
      if($('.nested-fields').length >= 3){
        $('.links').hide()
      }

      if($('.remove-nested').length > 1){
        $('.remove-nested').show()
      }
    })
  
  
    $('.nested').on('cocoon:after-remove', function() {
      if($('.nested-fields').length < 3){
        $('.links').show()
      }

      if($('.remove-nested').length === 1){
        $('.remove-nested').hide()
      }
    })
  }
}

export default component
