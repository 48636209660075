import { Component } from "@page/component"

const component = new Component({
  mixins: ['app/landlords/tools/credit_analysis/_form_remote.js']
})

component.ready = function () {
  backZimobi()
}

component.expose = {
  backZimobi(){
    $('#back-zimobi').on('click', function(){
      if (document.referrer == '') {
        window.location.href = '/app'
      } else {
        window.location.href = document.referrer
      }
    })
  }
}

export default component
