import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
    $('#btn-payment-modal').on('click', function(e){
        e.preventDefault()
        $('#payment-modal').modal('toggle')
    })
}

export default component
