import { Component } from "@page/component"

const component = new Component()

component.ready = function() {
  // Form validations
  let form = $("#credit_analysis_order_form")

  $(form).on("ajax:error", function (e, data, status, xhr) {
    let errors = JSON.parse(e['originalEvent']['detail'][0])
    $(form).render_form_errors('credit_analysis_order', errors)
  })

  // display validation errors on form
  $.fn.render_form_errors = function (model_name, errors) {
    let form = this
    form.clear_form_errors()

    $.each(errors, function (field, messages) {
      let input = form.find('input, select, textarea').filter(function () {
        field = field.replace("_in_cents", "")
        let name = $(this).attr('name')
        if (name) {
          let regex = `${model_name}\\[${field.replace('.', '_')}\\]`
          let regex2 = `${model_name}\\[${field.split('.')[0]}_attributes\\]\\[${field.split('.')[1]}\\]`
          let test = name.match(new RegExp(regex) || name.match(new RegExp(regex2)) )
          if (test)
            return true
        }
        return false
      })

      $(input).apply_validation_errors(messages)
    })
  }

  $.fn.clear_form_errors = function () {
    this.find('input, select, textarea').removeClass('is-invalid')
    this.find('span.invalid-feedback').remove()
  }

  $.fn.apply_validation_errors = function(messages){
    if(! (messages instanceof Array))
      messages = [messages]

    $(this).addClass('is-invalid')
    $(this).parent().append(
      '<span class="invalid-feedback d-block">' +
      $.map(messages, function (m) {
        return m.charAt(0).toUpperCase() + m.slice(1)
      }).join('<br />') +
      '</span>'
    )
  }
}

export default component
