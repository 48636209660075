import { Component } from "@page/component"
import Shepherd from 'shepherd.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const component = new Component()

component.expose = {
  callTour2() {

    // build tour
    const tour = new Shepherd.Tour({useModalOverlay: true})
    tour.on("start", ()=> disableBodyScroll(document.body));
    tour.on("cancel", ()=> clearAllBodyScrollLocks());
    tour.on("complete", ()=> clearAllBodyScrollLocks());

    tour.addStep({
      id: 'step-2-view',
      title: 'Etapa 2',
      text: `
        <div class="row">
            <div class="col d-flex">
                <a class="btn z-btn-circle-sm unclickable unlocked" data-status="unlocked" style="min-width: 54px">
                    <i class="fas fa-calendar fa-2x"></i>
                </a>
                <span class="ml-4">Clique nesse ícone para marcar uma visita agendada ao imóvel.</span>
            </div>
        </div>
    `,
      arrow: true,
      scrollTo: true,
      scrollToHandler: (window.isMobile() ? window.scrollItOnBottom : undefined),
      attachTo: {
        element: '#stage-2',
        on: 'bottom'
      }
    })

    // add custom close
    $("#stage-2").on('click', tour.cancel)

    // check if tour need be displayed
    Rails.ajax({
      type: 'GET',
      url: `/app/landlords/tours/check_user_tour`,
      data: new URLSearchParams({ tour_name: `lease_approach_contact_stage_2` } ).toString(),
      dataType: 'json',
      success: (data) => {
        if (data["show_tour"]) {
          tour.start()
        }
      }
    })
  }
}

export default component
