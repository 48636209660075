import { Component } from "@page/component"
import { FormValidator } from "@lib/form_validator/form_validator"

const component = new Component()

component.ready = function() {
  $('#form-assoc').on('DOMSubtreeModified', function(){
    applyMasks()
  })

  checkBonus($('.bonus:checked').val())
  $('.bonus').on('change', function(){
    checkBonus(this.value)
  })

  checkNeed($('.need:checked').val())
  $('.need').on('change', function(){
    checkNeed(this.value)
  })

  $('.penalty-interest-fields').toggle()
  $('#penalty-interest-check').on('change', function(){
    changeFieldDisplay($('.penalty-interest-fields'))
    if(this.checked){
      enableFields($('#lease_issuer_lease_issuer_value_attributes_penalty_percentage'))
      enableFields($('#lease_issuer_lease_issuer_value_attributes_interest_percentage'))
    }else{
      enableFields($('#lease_issuer_lease_issuer_value_attributes_penalty_percentage'))
      enableFields($('#lease_issuer_lease_issuer_value_attributes_interest_percentage'))
    }
  })

  $('#pre-validate-submit-3').on('click', function(){
    preValidateSubmit()
  })
}

component.start = function() {
  $(document).on('click', '.tab-link', function(e){
    $($(e.target).attr('href')).tab('show')
  })

  $(document).on('click', '#submit-3', function(e){
    $('#lease_issuer_form').submit()
  })
}

component.expose = {
  FormValidator,

  checkBonus(value) {
    if(value === 'true'){
      $('#bonus').removeClass('d-none')

      enableFields($('#bonus-field'))
    } else {
      $('#bonus').addClass('d-none')

      disableFields($('#bonus-field'))
    }
  },

  checkNeed(value) {
    if(value === 'true'){
      $('#need').removeClass('d-none')

      enableFields($('#need-field'))
    } else {
      $('#need').addClass('d-none')

      disableFields($('#need-field'))
    }
  },

  preValidateSubmit() {
    let validate = []

    // valor do aluguel
    validate.push( FormValidator.validatePresence($('#lease_issuer_lease_issuer_value_attributes_lease_value')) )

    // métodos de pagamento aceitos
    validate.push( FormValidator.validateAtLeastOneChecked($('input[type="checkbox"][name="lease_issuer[lease_issuer_value_attributes][payment_method][]"]')) )

    // bonus por pagamento em dia
    if ( $('#bonus_for_payment_on_time_check_true').filter(":checked").val() )
      validate.push( FormValidator.validatePresence($('#bonus-field')) )

    // multa e juros
    if ( $('#penalty-interest-check').filter(":checked").val() ) {
      validate.push( FormValidator.validatePresence($('#lease_issuer_lease_issuer_value_attributes_penalty_percentage')) )
      validate.push( FormValidator.validatePresence($('#lease_issuer_lease_issuer_value_attributes_interest_percentage')) )
      validate.push( FormValidator.validateBetween($('#lease_issuer_lease_issuer_value_attributes_interest_percentage'), 0, 1) )
      validate.push( FormValidator.validateBetween($('#lease_issuer_lease_issuer_value_attributes_penalty_percentage'), 0, 10) )
    }

    if( validate.every(check => check === true))
      $('#lease_issuer_form').submit()
  }

}

export default component
